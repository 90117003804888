import React from "react";
import "./index.scss";

import rpcClient from "../../modules/rpcClientModule";
import { animateBox } from "../../modules/componentAnimation";
import { countries } from "../../modules/countryModule";
import Dropdown from "../../components/customComponents/Dropdown";


const AdminTools = () => {
    const deleteCache = () => {
        rpcClient(
            {
                action: "call",
                method: "websites.clearCache",
                params: {},
                callback: res => { }
            }
        );
    }
    const deleteCacheForCountry = (country) => {
        rpcClient(
            {
                action: "call",
                method: "websites.clearCacheCountry",
                params: { Country: country },
                callback: res => { }
            }
        );
    }

    const openCRON = () => {
        console.log(rpcClient.baseURL);
        let tmp = rpcClient.baseURL.split(":");
        tmp.pop();
        tmp = `${tmp.join(":")}:4999`;
        window.open(tmp, "_blank");
    };

    return <div className="route__adminTools">
        <div className="route__adminTools__single">
            <div>
                <p className="route__adminTools__single__name">Clear cache</p>
                <p className="route__adminTools__single__description">
                    This function clears the cache by removing the rendered HTML from the database, allowing the content to be rendered again from scratch. By doing so, it ensures that the latest changes are reflected when the content is re-rendered.
                </p>
            </div>
            <button onClick={e => {
                animateBox(e, <YesNoModal text="This will clear the cache on all web pages, are you sure you want to continue?" fn={deleteCache} />)
            }}>Clear cache</button>
        </div>
        <div className="route__adminTools__single">
            <div>
                <p className="route__adminTools__single__name">Clear cache for country</p>
                <p className="route__adminTools__single__description">
                    This function clears the cache by removing the rendered HTML from the database for selected country, allowing the content to be rendered again from scratch. By doing so, it ensures that the latest changes are reflected when the content is re-rendered.
                </p>
            </div>
            <button onClick={e => {
                animateBox(e, <YesNoModal errorMsg={"Please select Country"} dropDownData={countries.map(c => {
                    return { name: <><span style={{ color: "rgb(234, 145, 63)" }}>Country: </span>{c.name}</>, value: c.code, search: c.name }
                })} text="This will clear the cache on all web pages for this country, if you want to continue please select coutry" fn={deleteCacheForCountry} />)
            }}>Clear cache</button>
        </div>
        <div className="route__adminTools__single">
            <div>
                <p className="route__adminTools__single__name">View CRON jobs</p>
                <p className="route__adminTools__single__description">
                    View all active/queued cron jobs
                </p>
            </div>
            <button onClick={e => {
                openCRON();
            }}>View</button>
        </div>
    </div>
};


const YesNoModal = (props) => {
    const [selectedDP, setSelectedDP] = React.useState();
    const [errorMsg, setErrorMsg] = React.useState();


    return <div className="route__adminTools__yesNo">
        <p>{props.text}</p>
        {props.dropDownData ? <Dropdown style={{
            marginTop: "20px"
        }} theme={"dark"} data={props.dropDownData} onChange={e => { setSelectedDP(e.value) }} /> : <></>}
        <p style={{
            marginTop: "20px"
        }}>{errorMsg}</p>
        <div className="route__usersList__addUser__wrap__btns">
            <p onClick={() => {
                if (props.dropDownData && !selectedDP) return setErrorMsg(props.errorMsg);
                props.fn(selectedDP);
                props.onClose();
            }}>

                <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                <span>Save</span>
            </p>
            <p onClick={props.onClose}>
                <span>Cancel</span>
                <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
            </p>
        </div>
    </div>
}


export default AdminTools;