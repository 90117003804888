import React from "react";
import "./scss/genericStyle.scss";

import { Routes, Route, useNavigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as userDataActions from "./actions/userDataActions";
import * as typesActions from "./actions/typesActions";
import rpcClient from "./modules/rpcClientModule"

import animateModule from "./modules/animateModule";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import ContentWrapper from "./components/ContentWrapper";
import Spinner from "./components/customComponents/Spinner";
import DomainsList from "./routes/Domains";
import UsersList from "./routes/Users";
import Websites from "./routes/Websites.js";
import AllowedSites from "./routes/AllowedSites";
import CampaignList from "./routes/CampaignsList/index.js";
import WebsitesToCheck from "./routes/WebsitesToCheck/index.js";
import Localization from "./routes/Localization/index.js";
import ApiTokens from "./routes/APITokens/index.js";
import AdminTools from "./routes/AdminTools/index.js";
import UserComments from "./routes/UserComments/index.js";

import Login from "./routes/Login";
import Test from "./routes/Test";


let typesPulled = false;
const App = () => {
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const userDataSelector = useSelector(state => state.userData ?? {});

  const mainDispatch = useDispatch();
  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
  };

  const checkLogin = () => {
    rpcClient({
      action: "call",
      method: "auth.checkLogin",
      callback: res => {
        if (res.status === "ok") {
          mainDispatch(userDataActions.setUserData(true, res.data));
          if ((window.location.href.includes("login") || window.location.toString().endsWith("/")) && !window.location.toString().includes("/main")) {
            animateNavigate("/main/campaign-list");
          };
        } else {
          if (!window.location.href.endsWith("/login")) {
            animateNavigate("/login");
          };
          mainDispatch(userDataActions.setUserData(false, null));
        };
      }
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, [timestampSelector]);


  React.useEffect(() => {
    if (!userDataSelector?.isLoggedIn) return;
    if (typesPulled) return;

    rpcClient({
      action: "call",
      method: "common.getTypes",
      callback: d => {
        if (d.status === "ok") {
          mainDispatch(typesActions.updateTypes(d.data));
        };
      }
    })
  }, [userDataSelector?.isLoggedIn]);

  return <>
    {userDataSelector?.isLoggedIn !== null ? <>
      <Header />
      {userDataSelector?.isLoggedIn ? <>
        <ContentWrapper className="component__contentWrapper--active">
          <Routes>
            <Route path="/" element={<>
              <Sidebar />
              <ContentWrapper className="component__mainContent" style={{ backgroundImage: 'url("/images/bcg.png")', backgroundSize: "100% 100%" }}>
                <Outlet />
              </ContentWrapper>
            </>}>
            </Route>

            <Route path="main/*" element={<>
              <Sidebar />
              <ContentWrapper className="component__mainContent" style={{ backgroundImage: 'url("/images/bcg2.png")', backgroundSize: "100% 100%" }}>
                <Outlet />
              </ContentWrapper>
            </>}>
              
            <Route path="campaign-list" element={<CampaignList />} />
            <Route path="domain-list" element={<DomainsList />} />
            <Route path="users-list" element={<UsersList />} />
            <Route path="prelanding-list" element={<Websites key="websites-list-1" type={1} />} />
            <Route path="landing-list" element={<Websites key="websites-list-2" type={2} />} />
            <Route path="thankyou-list" element={<Websites key="websites-list-3" type={3} />} />
            <Route path="static-list" element={<Websites key="websites-list-4" type={4} />} />
            <Route path="copy-writer" element={<WebsitesToCheck />} />
            <Route path="admin-tools" element={<AdminTools />} />
            <Route path="api-tokens-list" element={<ApiTokens />} />
            <Route path="localization" element={<Localization />} />
            <Route path="allowed-sites" element={<AllowedSites />} />
            <Route path="user-comments" element={<UserComments />} />


            </Route>

            <Route path="/test" element={<Test />} />
          </Routes>
        </ContentWrapper>
      </> : <ContentWrapper>
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      </ContentWrapper>}
    </> : <Spinner align="center" color="#3F7CEA" />}
  </>
};

export default App;
