import React from "react";
import "./index.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { animateBox } from "../../modules/componentAnimation";
import { AddCampaign } from "../../routes/CampaignsList/index.js";
import { AddDomain } from "../../routes/Domains";
import { AddUser } from "../../routes/Users";
import { AddWebsite } from "../../routes/Websites.js";
import { AddApiToken } from "../../routes/APITokens/index.js";
import { AddAllowedSite } from "../../routes/AllowedSites";
import { AddComment } from "../../routes/UserComments/index.js";

import { toggleAdvancedSearch } from "../../actions/siteSettingsActions";
import animateModule from "../../modules/animateModule";
import { updateTimestamp } from "../../actions/timestampActions";

const Header = () => {
    const curLocation = useLocation();
    const curDispatch = useDispatch();
    const curNavigate = useNavigate();

    const siteSettingsSelector = useSelector(state => state.siteSettings ?? {});
    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return <div className="component__header">
        <div className="component__header__left">
            <h3 onClick={() => animateNavigate("/main/")}><img src="/images/logo.png" /></h3>
        </div>
        <div className="component__header__right">
            {(curLocation.pathname.startsWith("/main/campaign-list")) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddCampaign />);
            }}>
                <img src="/images/addBtn.png" />
                <span>Add Campaign</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/domain-list") && userDataSelector?.UserInfo?.Flags?.isAdmin) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddDomain onChange={() => curDispatch(updateTimestamp())} />)
            }}>
                <img src="/images/addBtn.png" />
                <span>Add domain</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/users-list") && userDataSelector?.UserInfo?.Flags?.isAdmin) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddUser />);
            }}>
                <img src="/images/addBtn.png" />
                <span>Add User</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/prelanding-list") ||
                curLocation.pathname.startsWith("/main/landing-list") ||
                curLocation.pathname.startsWith("/main/static-list") ||
                curLocation.pathname.startsWith("/main/thankyou-list")) && <p className="component__header__right__btn" onClick={(e) => {
                    animateBox(e, <AddWebsite />);
                }}>
                <img src="/images/addBtn.png" />
                <span>Import website</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/api-tokens-list") && userDataSelector?.UserInfo?.Flags?.isAdmin) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddApiToken />);
            }}>
                <img src="/images/addBtn.png" />
                <span>Add Token</span>
            </p>}

            {(curLocation.pathname.startsWith("/main/allowed-sites") && userDataSelector?.UserInfo?.Flags?.isAdmin) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddAllowedSite />);
            }}>
                <img src="/images/addBtn.png" />
                <span>Add allowed site</span>
            </p>}

            {(curLocation.pathname.startsWith("/main/user-comments") && userDataSelector?.UserInfo?.Flags?.isCommentWriter) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddComment />);
            }}>
                <img src="/images/addBtn.png" />
                <span>Add comment</span>
            </p>}

            {curLocation.pathname.startsWith("/main") && <p className="component__header__right__btn component__header__right__btn--orange" onClick={() => {
                curDispatch(toggleAdvancedSearch());
            }}>
                <img style={{ width: "16px", height: "16px" }} src="/images/filterImg.png" />
                <span>{siteSettingsSelector.advancedSearch ? "Simple" : "Advanced"} filters</span>
            </p>}

           
        </div>
    </div>
};



export default Header;