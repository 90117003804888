import { combineReducers } from "redux";
import timestampReducer from "./timestampReducer";
import userDataReducer from "./userDataReducer";
import siteSettingsReducer from "./siteSettingsReducer";
import typesReducer from "./typesReducer";

export default combineReducers({
    timestamp: timestampReducer,
    userData: userDataReducer,
    siteSettings: siteSettingsReducer,

    types: typesReducer
});