import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as backendModule from './backendModule'

let openModals = [];

export const animateBox = (box, childElem = null) => {
    if (!box && childElem) {
        let tmpRoot = document.querySelector("#root");
        if (tmpRoot) {
            box = {currentTarget: tmpRoot};
        };
    } else if (box && !childElem) {
        let tmpRoot = document.querySelector("#root");
        if (tmpRoot) {
            childElem = box;
            box = {currentTarget: tmpRoot};
        };
    };
    return new Promise(resolve => {
        let animID = Date.now();
        let cloned = box.currentTarget.cloneNode(true)

        if (cloned.id) cloned.id = "";
        cloned.style.position = 'absolute'
        cloned.classList.add("cloned-element")
        cloned.style.top = box.currentTarget.getBoundingClientRect().y + 'px'
        cloned.style.left = box.currentTarget.getBoundingClientRect().x + 'px'
        cloned.style.width = box.currentTarget.getBoundingClientRect().width + 'px'
        cloned.style.margin = 0
        cloned.style.overflow = 'hidden'
        cloned.style.borderRadius = '0'
        cloned.style.transition = 'all 0.5s ease'
        cloned.style.transformOrigin = "center"
        cloned.style.zIndex = "999";
        cloned.style.opacity = 0;
        document.querySelector('body').appendChild(cloned)
        let cmp = getComputedStyle(cloned);
        let bnd = box.currentTarget.getBoundingClientRect();

        let offsetX = Math.floor((document.body.clientWidth / 2) - Math.round(document.body.clientWidth / 2));
        let offsetY = Math.floor((document.body.clientHeight / 2) - Math.round(document.body.clientHeight / 2));
        
        let cmpCloned = {
            top: cmp.top,
            left: cmp.left,
            width: `${bnd.width}px`,
            height: `${bnd.height}px`,
            backgroundColor: cmp.backgroundColor,
            padding: cmp.padding,
            offsetX,
            offsetY
        };

        cloned.animate([
            {
                top: cmp.top,
                left: cmp.left,
                transform: 'none',
                padding: cmp.padding,
                width: `${bnd.width}px`,
                height: `${bnd.height}px`,
                backgroundColor: cmp.backgroundColor,
            }, {
                top: `50%`,
                left: `50%`,
                padding: 0,
                transform: `translate(-50%, -50%) translateX(${offsetX}px) translateY(${offsetY}px)`,
                width: `100%`,
                height: `100%`,
                backgroundColor: "#0000006e"
            }
        ], {
            duration: 0,
            iterations: 1,
            fill: 'both',
            easing: 'ease-in-out'
        }).onfinish = () => {
            let clonedHTML = cloned.innerHTML
            cloned.innerHTML = ''
            if (childElem) {
                openModals.push({
                    id: animID,
                    close: () => closeModal(clonedHTML, cloned, cmpCloned, animID)
                });
                createRoot(cloned).render(<Provider store={backendModule.getStore()}>
                    {React.cloneElement(childElem, {
                        onClose: (animate = true) => closeModal(clonedHTML, cloned, cmpCloned, animID, animate)
                    })}
                </Provider>)
            }
            cloned.animate([
                {
                    opacity: 0
                }, {
                    opacity: 1
                }
            ], {
                duration: 300,
                iterations: 1,
                fill: 'both',
                easing: 'ease-in-out'
            }).onfinish = () => {
                resolve()
            };
        }
    })
}
export const closeModal = (original, clone, cmp, animID, animate) => {
    openModals = openModals.filter(t => t.id !== animID);

    return new Promise(resolve => {
        clone.animate([
            {
                opacity: 1
            }, {
                opacity: 0
            }
        ], {
            duration: animate ? 300 : 0,
            iterations: 1,
            fill: 'both',
            easing: 'ease-in-out'
        }).onfinish = () => {
            clone.remove()
            resolve();
        };
    });
}

export const closeAllModals = () => {
    return new Promise(resolve => {
        while (openModals.length > 0) {
            openModals.pop().close();
        };
        return resolve();
    });
};