import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import rpcClient from "../../modules/rpcClientModule";
import * as backendModule from "../../modules/backendModule";
import { updateTimestamp } from "../../actions/timestampActions";
import { animateBox } from "../../modules/componentAnimation";

import Spinner from "../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import CustomInput from "../../components/customComponents/CustomInput";
import Dropdown from "../../components/customComponents/Dropdown";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";

let curTimeout = null;
const ApiTokens = () => {
    const [data, setData] = React.useState();
    const [users, setUsers] = React.useState();
    const [flags, setFlags] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const curTimestampSelector = useSelector(state => state?.timestamp ?? null);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});

    const searchRef = React.useRef();

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{
                or: [
                    { name: "Name", op: "like", value: searchVal },
                    { name: "UserID", op: "like", value: searchVal },
                ]
            }]);
        }, 500);
    };

    const getUsers = () => {
        rpcClient({
            action: "call",
            method: "users.getAll",
            params: {},
            callback: d => {
                setUsers({ status: d.status, data: d.data });
            }
        });

    };

    const getFlags = () => {
        rpcClient({
            action: "call",
            method: "apiTokens.getAllAvailableFlags",
            params: {},
            callback: d => {
                setFlags({ status: d.status, data: d.data });
            }
        });

    };

    const getData = () => {
        rpcClient({
            action: "call",
            method: "apiTokens.getAll",
            params: {},
            callback: d => {
                setData({status: d.status, data:d.data});
            }
        });

    };

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector?.advancedSearch]);

    React.useEffect(() => {
        getData();
        getUsers();
        getFlags();
    }, [curTimestampSelector, filters]);

    return <div className="route__apiTokens" style={{ paddingTop: "20px" }}>
        {!siteSettingsSelector.advancedSearch && <CustomInput autocomplete="off" onChange={searchHandler} ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{ width: "100%", marginBottom: "20px" }} />}
        <FilteredCustomTable
            theme="dark"
            accent="#48515C"
            style={{ width: "100%" }}
            headers={["ID", "Name", "CreatedAt"]}
            filters={siteSettingsSelector.advancedSearch ? [
                { name: "ID", friendlyName: "ID", type: "string" },
                { name: "UserID", friendlyName: "UserID", type: "string" },
                { name: "Name", friendlyName: "Name", type: "string" }
            ] : undefined}
            filterCB={f => setFilters(f)}
            data={(() => {
                if (!data || !flags || !users) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (data.status === "error" || users.status === "error" || flags.status === "error") return [[{ keyID: "noData-text", type: "custom", data: <p>There was an error while fetching users</p> }]];

                let tmp = data.data.map(elem => {
                    return [
                        { keyID: elem.ID, type: "text", text: elem.ID },
                        { keyID: elem.ID, type: "text", text: elem.Name },
                        { keyID: elem.ID, type: "text", text: (new Date(elem.createdAt)).toLocaleString() },
                        {
                            keyID: elem.ID, type: "groupNewline", group: [
                                {
                                    keyID: elem.ID, type: "text", text: <p className="route__apiTokens__flags">
                                        <span>Flags</span>
                                        {Object.keys(elem.Flags).map(key => {
                                            if (!elem.Flags[key]) return null;
                                            if (!flags) return null;
                                            if (flags.status !== "ok") return null;
                                            let foundFlag = flags.data.find(f => f.name === key);
                                            return <span>{foundFlag.friendlyName}</span>;
                                        })}
                                    </p>
                                }
                            ]
                        },
                        {
                            keyID: elem.ID, type: "groupNewline", group: [
                                {
                                    keyID: elem.ID, type: "button", text: "View token", style: { marginRight: "10px" }, triggerDropdown: true, triggerData: c => {
                                        return <ViewToken onClose={c} ID={elem.ID} />
                                    }
                                },
                                { keyID: elem.ID, type: "button", text: "Edit", style: { marginRight: "10px" }, onClick: e => animateBox(e, <AddApiToken data={elem} />) },
                                { keyID: elem.ID, type: "button", text: "Remove", onClick: e => animateBox(e, <RemoveToken data={elem} />) },
                            ]
                        }
                    ];
                });

                if (tmp.length === 0) tmp.push([{ keyID: "noData-empty", type: "custom", data: <p>Nothing to show.</p> }])
                return tmp;
            })()}
        />
    </div>
};

const AddApiToken = props => {
    const [users, setUsers] = React.useState();
    const [flags, setFlags] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [selectedUser, setSelectedUser] = React.useState();
    const [selectedFlags, setSelectedFlags] = React.useState({});

    const curDispatch = useDispatch();
    const nameRef = React.useRef();

    const getUsers = () => {
        rpcClient({
            action: "call",
            method: "users.getAll",
            params: {},
            callback: d => {
                setUsers({ status: d.status, data: d.data });
            }
        });
    };

    const getFlags = () => {
        rpcClient({
            action: "call",
            method: "apiTokens.getAllAvailableFlags",
            params: {},
            callback: d => {
                setFlags({ status: d.status, data: d.data });
            }
        });
    };

    const addToken = () => {
        setInfoP("");
        let data = {
            Name: nameRef.current.value,
            UserID: selectedUser,
            Flags: selectedFlags
        };

        if (!data.Name) return setInfoP("Name can't be empty");
        if (!data.UserID) return setInfoP("User must be selected");
        if (!data.Flags) return setInfoP("Flags werent set, what?!");
        if (props.data) data["ID"] = props.data.ID;

        setSpinner(true);
        rpcClient({
            action: "call",
            method: `apiTokens.${props.data ? "edit" : "add"}`,
            params: data,
            callback: d => {
                if (d.status === "ok") {
                    props.onClose();
                    curDispatch(updateTimestamp());
                } else {
                    setInfoP(`There was an error while ${props.data ? "editing" : "creating"} the API token`);
                };
                setSpinner(false)
            }
        });

    };

    React.useEffect(() => {
        if (!props.data) return;

        nameRef.current.value = props.data.Name;
        setSelectedUser(props.data.UserID);
        setSelectedFlags(() => { return { ...props.data.Flags } });
    }, [props.data]);

    React.useEffect(() => {
        getUsers();
        getFlags();
    }, []);

    return <div className="route__apiTokens__addToken">
        <div className="route__apiTokens__addToken__wrap">
            <div className="route__apiTokens__addToken__wrap__spinner" style={{
                opacity: (spinner || !users || !flags) ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>{props.data ? "Edit" : "Add"} API token</h3>
            <CustomInput autocomplete="off" ref={nameRef} theme="dark" accent="#fff" placeholder="Token name" style={{ marginBottom: "20px", width: "100%" }} />
           
          <Dropdown
                theme="dark"
                accent="#fff"
                inlinePlaceholder="Bound user"
                data={(users?.status === "ok" ? users.data : []).map(u => {
                    return { name: u.Username, value: u.ID }
                })}
                selected={(() => {
                    if (!users) return null;
                    if (users.status !== "ok") return null;
                    if (!selectedUser) return null;

                    return users.data.indexOf(users.data.find(u => u.ID === selectedUser));
                })()}
                onChange={e => setSelectedUser(e?.value)}
            />
            {(flags?.status === "ok") && <div className="route__apiTokens__addToken__wrap__flags">
                {flags.data.map(flg => {
                    return <CustomCheckbox theme="dark" accent="rgb(63, 124, 234)" placeholder={flg.friendlyName} defaultValue={!!selectedFlags[flg.name]} onChange={e => {
                        return setSelectedFlags(flgs => {
                            return { ...flgs, [flg.name]: !!e }
                        });
                    }} />
                })}
            </div>}


            <div className="route__apiTokens__addToken__wrap__btns">
                <p onClick={addToken}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__apiTokens__addToken__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveToken = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const curDispatch = useDispatch();

    const removeToken = () => {
        setInfoP("");
        setSpinner(true);
        rpcClient({
            action: "call",
            method: "apiTokens.remove",
            params: { ID: props.data.ID },
            callback: d => {
                if (d.status === "ok") {
                    props.onClose();
                    curDispatch(updateTimestamp());
                } else {
                    setInfoP("There was an error while removing the API token!");
                };
                setSpinner(false)
            }
        });

    };

    return <div className="route__apiTokens__addToken">
        <div className="route__apiTokens__addToken__wrap">
            <div className="route__apiTokens__addToken__wrap__spinner" style={{
                opacity: (spinner) ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Remove API token</h3>
            <p>Are you sure?</p>
            <p>Removal of <span style={{ color: "rgb(63, 124, 234)" }}>{props.data.Name}</span> is irreversible!</p>


            <div className="route__apiTokens__addToken__wrap__btns">
                <p onClick={removeToken}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Remove</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__apiTokens__addToken__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const ViewToken = props => {
    const [token, setToken] = React.useState();

    React.useEffect(() => {
        setToken();
        if (!props.ID) return setToken({ status: "error" });
        rpcClient({
            action: "call",
            method: `apiTokens.getOne`,
            params: {ID: props.ID},
            callback: d => {
                setToken({ status: d.status, data: d.data })
            }
        });
        
    }, [props.ID]);

    if (!token) return <Spinner style={{ width: "32px", height: "32px" }} />
    if (token.status === "error") return <p style={{ color: "#fd9999" }}>There was an error while fetching the token</p>
    return <p style={{ color: "rgb(0, 163, 255)" }}>{token.data}</p>
};

export default ApiTokens;
export { AddApiToken };