import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import rpcClient from "../../modules/rpcClientModule";
import * as timestampActions from "../../actions/timestampActions";
import { animateBox } from "../../modules/componentAnimation";
import useOnScreen from "../../modules/hooks/useOnScreen";

import CustomInput from "../../components/customComponents/CustomInput";
import Spinner from "../../components/customComponents/Spinner";
import Dropdown from "../../components/customComponents/Dropdown";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import FilterPanel from "../../components/customComponents/FilterPanel";

import ImageSelectModal from "../../components/modals/ImageSelectModal";

let curTimeout = null;
const UserComments = () => {
    const [data, setData] = React.useState();
    const [offers, setOffers] = React.useState();
    const [canPagiante, setCanPaginate] = React.useState(false);
    const [viewReplies, setViewReplies] = React.useState(false);
    const [filters, setFilters] = React.useState([]);

    const curOnScreen = useOnScreen();
    const timestampRef = React.useRef();
    const searchRef = React.useRef();

    const timestampSelector = useSelector(state => state?.timestamp ?? null);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{
                or: [
                    { name: "Name", op: "like", value: searchVal },
                    { name: "Comment", op: "like", value: searchVal }
                ]
            }]);
        }, 500);
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        rpcClient({
            action: "call",
            method: "userSpecific.comments.getAll",
            args: {
                filters: [
                    {name: "ParentID", op: "eq", value: null},
                    ...filters
                ],
                orders: [
                    {name: "createdAt", order: "desc"}
                ]
            },
            callback: d => {
                if (d.status === "ok") {
                    if (d.data.length >= 20) setCanPaginate(true);
                };
                setData(d);
            }
        });
    };

    const continueData = ts => {
        if (!data) return;
        if (data?.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        if (!canPagiante) return;

        rpcClient({
            action: "call",
            method: "userSpecific.comments.getAll",
            args: {
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)},
                    {name: "ParentID", op: "eq", value: null},
                    ...filters
                ],
                orders: [
                    {name: "createdAt", order: "desc"}
                ]
            },
            callback: d => {
                if (d.status === "ok") {
                    if (d.data.length >= 20) setCanPaginate(true);
                    setData(dt => {
                        return {
                            ...dt.data,
                            ...d.data
                        };
                    });
                };
            }
        });
    };

    React.useEffect(() => {
        if (!canPagiante) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [canPagiante, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        getData();
    }, [timestampSelector, filters]);

    React.useEffect(() => {
        rpcClient({
            action: "call",
            method: "offers.getAll",
            args: {limit: null},
            callback: setOffers
        })
    }, []);

    return <div className="route__userComments">
        {siteSettingsSelector?.advancedSearch ?
            <FilterPanel accent="rgb(63, 124, 234)" theme="dark" filters={[
                { name: "Name", friendlyName: "Name", type: "string" },
                { name: "Comment", friendlyName: "Comment", type: "string" },
                { name: "Likes", friendlyName: "Likes", type: "number" },
                { name: "Dislikes", friendlyName: "Dislikes", type: "number" },
                { name: "Gender", friendlyName: "Gender", type: "custom", varType: "boolean", data: [
                    {text: "Male", value: true},
                    {text: "Female", value: false}
                ] },
                { name: "showFirst", friendlyName: "Show first", type: "custom", varType: "boolean", data: [
                    {text: "Yes", value: true},
                    {text: "No", value: false}
                ] },
                { name: "OfferID", friendlyName: "Offer", type: "custom", varType: "string", data: (offers?.status === "ok" ? offers.data : []).map(offer => {
                    return {text: `${offer.OfferName} (${offer.Country ?? "?"}, ${offer.OfferType ?? "?"})`, value: offer.ID}
                }) }
                
            ]} filterCB={f => setFilters(f)} /> :
            <>
                <CustomInput style={{
                    width:"100%",
                    marginBottom: "20px"
                }} autocomplete="off" ref={searchRef} onInput={searchHandler} theme="dark" accent="#3F7CEA" placeholder="Search..." />
            </>
        }

        <CustomCheckbox placeholder="Show replies" theme="dark" style={{marginBottom: "20px"}} onChange={(e) => e !== viewReplies && setViewReplies(e)} />
        {data ? (data.status === "ok" ? <>
            {data.data.map(chat => {
                return <ChatItem depth={0} {...chat} viewReplies={viewReplies} key={`chat-${chat.ID}-${timestampSelector}`} data={chat} />
            })}
        </> : <p>There was an error while fetching comments!</p>) : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
    </div>
};

const ChatItem = props => {
    const [additionalComments, setAdditionalComments] = React.useState();

    let depthIncrement = 40;
    let finalDepth = depthIncrement * (props.depth ?? 0);

    const getData = () => {
        if (!props.viewReplies) return setAdditionalComments({status: "ok", data: []});

        rpcClient({
            action: "call",
            method: "userSpecific.comments.getAll",
            args: {
                filters: [
                    {name: "ParentID", op: "eq", value: props.ID}
                ],
                orders: [
                    {name: "createdAt", order: "asc"}
                ]
            },
            callback: setAdditionalComments
        });
    };

    React.useEffect(() => {
        getData();
    }, [props.viewReplies]);

    return <>
        <div className={`route__userComments__chat ${props.depth > 0 ? "route__userComments__chat--inset" : ""}`} style={{
            width: `calc(100% - ${finalDepth}px)`,
            marginLeft: `${finalDepth}px`
        }}>
            <div className="route__userComments__chat__img">
                <img src={props.UserImage ?? "#"} onError={e => e.target.src = "/images/generic_user.webp"} />
            </div>
            <div className="route__userComments__chat__content">
                <div className="route__userComments__chat__content__top">
                    {props.Name}
                    <span>{props._Offer?.OfferName} ({props._Offer?.Country ?? "?"}, {props._Offer?.OfferType ?? "?"})</span>
                </div>
                <div className="route__userComments__chat__content__bottom">
                    {props.Comment}
                    {props.TextImage && <img src={props.TextImage} />}

                    <div className="route__userComments__chat__content__bottom__icons">
                        <p><img src="/images/downArrow.png" style={{transform: "rotate(180deg)"}} /><span>{props.LikesCount ?? 0}</span></p>
                        <p><img src="/images/downArrow.png" /><span>{props.DislikesCount ?? 0}</span></p>

                        <div className="genericModal__wrap__btns route__userComments__chat__content__bottom__icons__buttons">
                            <p onClick={() => animateBox(<AddComment skipTimestamp={true} onChange={getData} offerID={props.OfferID} parentID={props.ID} />)}>
                                <img style={{ marginRight: "10px", width: "24px", height: "24px" }} src="/images/addBtn.png" />
                                <span>Add reply</span>
                            </p>

                            <p onClick={() => animateBox(<AddComment data={props.data} skipTimestamp={false} onChange={getData} offerID={props.OfferID} parentID={props.ID} />)}>
                                <img style={{ marginRight: "10px", width: "24px", height: "24px" }} src="/images/saveBtn.png" />
                                <span>Edit</span>
                            </p>

                            <p onClick={() => animateBox(<RemoveComment data={props.data} />)}>
                                <img style={{ marginRight: "10px", width: "24px", height: "24px" }} src="/images/trashCan.png" />
                                <span>Remove</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {!additionalComments && <div className="route__userComments__chat route__userComments__chat--inset" style={{
            width: `calc(100% - ${finalDepth + depthIncrement}px)`,
            marginLeft: `${finalDepth + depthIncrement}px`
        }}>
            <Spinner style={{width: "32px", height: "32px"}} color="white" />
        </div>}
        {additionalComments?.data?.length > 0 && additionalComments.data.map((chat, chatIdx) => {
            return <ChatItem isChild={chatIdx === additionalComments.data.length - 1 ? false : true} viewReplies={props.viewReplies} depth={(props.depth ?? 0) + 1} {...chat} key={`chat-${chat.ID}`} data={chat} />
        })}
        {(additionalComments?.data?.length === 0 && props.depth === 0) && <>
            <br />
        </>}
    </>
};

const AddComment = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [offers, setOffers] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState(props.offerID || undefined);
    const [gender, setGender] = React.useState();
    const [userImage, setUserImage] = React.useState();
    const [textImage, setTextImage] = React.useState();
    const [showFirst, setShowFirst] = React.useState();
    const [infoP, setInfoP] = React.useState("");


    const nameRef = React.useRef();
    const commentRef = React.useRef();
    const likesRef = React.useRef();
    const dislikesRef = React.useRef();

    const curDispatch = useDispatch();

    const addComment = () => {
        setInfoP("");

        let data = {
            ParentID: props.parentID,
            OfferID: props.offerID || selectedOffer,
            Name: nameRef.current.value,
            Gender: !!gender,
            Comment: commentRef.current.value,
            LikesCount: likesRef.current.value,
            DislikesCount: dislikesRef.current.value,
            UserImage: userImage,
            TextImage: textImage,
            showFirst: !!showFirst
        };

        if (!data.OfferID) return setInfoP("Offer can't be empty");
        if (!data.Name) return setInfoP("Commenter name can't be empty");
        if (!data.Comment) return setInfoP("Comment can't be empty");
        data.LikesCount = Number(data.LikesCount);
        if (isNaN(data.LikesCount) || data.LikesCount < 0) return setInfoP("Likes are invalid");
        data.DislikesCount = Number(data.DislikesCount);
        if (isNaN(data.DislikesCount) || data.DislikesCount < 0) return setInfoP("Likes are invalid");

        if (props.data) data["ID"] = props.data.ID;
        if (props.data?.ParentID) {
            data["ParentID"] = props.data.ParentID;
        } else {
            if (props.data) delete data["ParentID"];
        };

        // TODO: rpc and onChange/onClose
        setSpinner(true);
        rpcClient({
            action: "call",
            method: `userSpecific.comments.${props.data ? "edit" : "add"}`,
            args: {...data},
            callback: d => {
                if (d.status === "ok") {
                    if (typeof(props.onChange) === "function") props.onChange(d.data);
                    if (!props.skipTimestamp) curDispatch(timestampActions.updateTimestamp());
                    props.onClose();
                } else {
                    setInfoP(`There was an error while ${props.data ? "editing" : "creating"} a comment`);
                };
            }
        }).finally(() => setSpinner(false));
    };

    const getOffers = () => {
        rpcClient({
            action: "call",
            method: "offers.getAll",
            params: { limit: null },
            callback: setOffers
        });
    };

    React.useLayoutEffect(() => {
        if (!props.data) return;

        if (props.data.OfferID) setSelectedOffer(props.data.OfferID);
        setGender(!!props.data.Gender);
        setShowFirst(!!props.data.showFirst);
        if (props.data.UserImage) setUserImage(props.data.UserImage);
        if (props.data.TextImage) setTextImage(props.data.TextImage);
    }, [props.data]);

    React.useEffect(() => {
        getOffers();
    }, []);

    return <div className="genericModal route__userComments__addComment">
        <div className="genericModal__wrap">

            <div className="genericModal__wrap__spinner" style={{opacity: spinner ? 1 : 0, pointerEvents: spinner ? "all" : "none"}} onClick={e => spinner && e?.stopPropagation?.()}>
                <Spinner style={{width: "32px", height: "32px", pointerEvents: "none"}} color="white" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>{props.data ? "Edit" : "Add"} comment</h3>

            {offers ? (offers.status === "ok" ? <>
                {!props.offerID && <Dropdown
                    theme="dark"
                    accent="rgb(150, 150, 152)"
                    inlinePlaceholder="Select offer"
                    style={{marginBottom: "20px"}}
                    data={offers.data.map(o => {
                        return {
                            name: <><span style={{ color: "#ea913f" }}>Offer: </span>{`${o.OfferName} (${o.OfferType ?? "-"}, ${o.Country ?? "-"})`}</>,
                            value: o.ID,
                            search: `${o.OfferName} (${o.OfferType ?? "-"}, ${o.Country ?? "-"})`
                        };
                    })}
                    onChange={e => e?.value && setSelectedOffer(e?.value)}
                    selected={(()=>{
                        if (!selectedOffer) return null;
                        if (offers?.status !== "ok") return null;

                        return offers.data.indexOf(offers.data.find(o => o.ID === selectedOffer));
                    })()}
                />}
                
                {selectedOffer && <>
                    <Dropdown
                        theme="dark"
                        accent="rgb(150, 150, 152)"
                        inlinePlaceholder="Gender"
                        style={{marginBottom: "20px"}}
                        data={[
                            {name: <><span style={{ color: "#ea913f" }}>Gender: </span> Male</>, value: true, search: "male"},
                            {name: <><span style={{ color: "#ea913f" }}>Gender: </span> Female</>, value: false, search: "female"},
                        ]}
                        onChange={e => e?.value !== undefined && setGender(e?.value)}
                        selected={(()=>{
                            if (gender === null || gender === undefined) return null;
                            switch (gender) {
                                case true: return 0;
                                case false: return 1;
                                default: return null;
                            };
                        })()}
                    />
                    <CustomInput defaultValue={props?.data?.Name ?? ""} autocomplete="off" ref={nameRef} theme="dark" accent="#fff" placeholder="Commenter name" style={{ marginBottom: "20px", width: "100%" }} />
                    <textarea defaultValue={props?.data?.Comment ?? ""} ref={commentRef} placeholder="Comment..." style={{ marginBottom: "20px", width: "100%" }}></textarea>
                    <div className="route__userComments__group">
                        <CustomInput defaultValue={props?.data?.LikesCount ?? "0"} type="number" autocomplete="off" ref={likesRef} theme="dark" accent="#fff" placeholder="Likes" style={{ marginBottom: "20px", width: "100%" }} />
                        <CustomInput defaultValue={props?.data?.DislikesCount ?? "0"} type="number" autocomplete="off" ref={dislikesRef} theme="dark" accent="#fff" placeholder="Dislikes" style={{ marginBottom: "20px", width: "100%" }} />
                    </div>

                    <CustomCheckbox
                        placeholder="Show first"
                        theme="dark"
                        onChange={e => setShowFirst(!!e)}
                        defaultValue={showFirst}
                    />

                    <div className="route__userComments__group">
                        <div className="route__userComments__addComment__img">
                            <p>Profile image</p>
                            <div className="route__userComments__addComment__img__url" onClick={() => {
                                animateBox(<ImageSelectModal tag="comment-avatar" onChange={e => setUserImage(e)} />)
                            }}>
                                {userImage ? <img src={userImage} /> : <p>Click to select an image</p>}
                                {userImage && <span style={{backgroundImage: "url('/images/closeBtn.png')"}} onClick={e => {
                                    e?.stopPropagation?.();
                                    setUserImage(undefined);
                                }}></span>}
                            </div>
                        </div>

                        <div className="route__userComments__addComment__img">
                            <p>Article image</p>
                            <div className="route__userComments__addComment__img__url" onClick={() => {
                                animateBox(<ImageSelectModal tag="comment-article" onChange={e => setTextImage(e)} />)
                            }}>
                                {textImage ? <img src={textImage} /> : <p>Click to select an image</p>}
                                {textImage && <span style={{backgroundImage: "url('/images/closeBtn.png')"}} onClick={e => {
                                    e?.stopPropagation?.();
                                    setTextImage(undefined);
                                }}></span>}
                            </div>
                        </div>
                    </div>
                </>}
            </> : <p>There was an error while fetching offers!</p>) : <Spinner color="white" style={{width: "32px", height: "32px"}} />}

            {infoP && <p className="genericModal__wrap__infoP">{infoP}</p>}

            <div className="genericModal__wrap__btns" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                {offers?.status === "ok" && <p onClick={addComment}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>{props.data ? "Edit" : "Add"}</span>
                </p>}

                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

        </div>
    </div>
};

const RemoveComment = props => {
    const [infoP, setInfoP] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);

    const curDispatch = useDispatch();

    const removeComment = () => {
        setSpinner(true);
        rpcClient({
            action: "call",
            method: "userSpecific.comments.remove",
            args: {ID: props.data.ID},
            callback: d => {
                if (d.status === "ok") {
                    curDispatch(timestampActions.updateTimestamp());
                    props.onClose();
                } else {
                    setInfoP("There was an error while removing the comment!");
                };
            }
        }).finally(() => setSpinner(false));
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">

            <div className="genericModal__wrap__spinner" style={{opacity: spinner ? 1 : 0, pointerEvents: spinner ? "all" : "none"}} onClick={e => spinner && e?.stopPropagation?.()}>
                <Spinner style={{width: "32px", height: "32px", pointerEvents: "none"}} color="white" />
            </div>            

            <h3 style={{marginBottom: "20px"}}>Delete comment</h3>
            <p style={{marginBottom: "20px"}}>Are you sure? This will remove the comment and all of its replies!</p>

            {infoP && <p className="genericModal__wrap__infoP">{infoP}</p>}

            <div className="genericModal__wrap__btns" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <p onClick={removeComment}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>{props.edit ? "Edit" : "Add"}</span>
                </p>

                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

        </div>
    </div>
};

export default UserComments;
export { AddComment };