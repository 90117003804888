import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import rpcClient from "../../modules/rpcClientModule";
import * as backendModule from "../../modules/backendModule";
import { updateTimestamp } from "../../actions/timestampActions";
import { animateBox } from "../../modules/componentAnimation";

import Spinner from "../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import CustomInput from "../../components/customComponents/CustomInput";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";

let curTimeout = null;
const UsersList = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const curUserData = useSelector(state => state?.userData?.userData ?? {});
    const curTimestampSelector = useSelector(state => state?.timestamp ?? null);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});

    const searchRef = React.useRef();

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{
                or: [
                    { name: "Username", op: "like", value: searchVal },
                    { name: "Email", op: "like", value: searchVal },
                ]
            }]);
        }, 500);
    };

    const getData = () => {
        rpcClient({
            action: "call",
            method: `users.getAll`,
            params: {
                filters
            },
            callback: res => {
                if (res.status === "ok") {
                    setData(res);
                } else {
                    setData({ status: "error", data: "SERVER_ERROR" });
                };
            }
        });

    };

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector?.advancedSearch]);

    React.useEffect(() => {
        getData();
    }, [curTimestampSelector, filters]);

    return <div className="route__usersList" style={{ paddingTop: "20px" }}>
        {!siteSettingsSelector.advancedSearch && <CustomInput autocomplete="off" onChange={searchHandler} ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{ width: "100%", marginBottom: "20px" }} />}
        <FilteredCustomTable
            theme="dark"
            accent="#48515C"
            style={{ width: "100%" }}
            headers={["ID", "Username", "E-Mail", "Admin"]}
            filters={siteSettingsSelector.advancedSearch ? [
                { name: "ID", friendlyName: "ID", type: "string" },
                { name: "Username", friendlyName: "Username", type: "string" },
                { name: "Email", friendlyName: "Email", type: "string" },
                { name: "Flags:isAdmin", friendlyName: "Is admin", type: "boolean" }
            ] : undefined}
            filterCB={f => setFilters(f)}
            data={(() => {
                if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (data?.status === "error") return [[{ keyID: "noData-text", type: "custom", data: <p>There was an error while fetching users</p> }]];

                let tmp = data.data.map(elem => {
                    return [
                        { keyID: String(elem.ID), type: "text", text: elem.ID },
                        { keyID: String(elem.ID), type: "text", text: elem.Username },
                        { keyID: String(elem.ID), type: "text", text: elem.Email },
                        { keyID: String(elem.ID), type: "text", text: elem.Flags?.isAdmin ? <span style={{ color: "#53ff53" }}>Yes</span> : <span style={{ color: "#ff7474" }}>No</span> },
                        {
                            keyID: String(elem.ID), type: "groupNewline", group: (curUserData?.UserInfo?.Flags?.isAdmin ? [
                                { keyID: String(elem.ID), type: "button", text: "Edit", onClick: e => animateBox(e, <AddUser data={elem} />), style: { marginRight: "10px" } },
                                { keyID: String(elem.ID), type: "button", text: "Remove", onClick: e => animateBox(e, <RemoveUser data={elem} />) },
                            ] : [])
                        }
                    ];
                });

                if (tmp.length === 0) tmp.push({ keyID: "noData-empty", type: "custom", data: <p>Nothing to show.</p> })
                return tmp;
            })()}
        />
    </div>
};

const AddUser = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const [fAdmin, setFAdmin] = React.useState(false);
    const [fCopy, setFCopy] = React.useState(false);
    const [fCommentWriter, setFCommentWriter] = React.useState(false);

    const usernameRef = React.useRef();
    const firstnameRef = React.useRef();
    const lastnameRef = React.useRef();
    const trackIDRef = React.useRef();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();

    const curDispatch = useDispatch();

    const addUser = () => {
        setInfoP("");

        const data = {
            username: usernameRef.current.value,
            firstname: firstnameRef.current.value,
            lastname: lastnameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            trackID: trackIDRef.current.value,

            flags: {
                isAdmin: !!fAdmin,
                isCopyWriter: !!fCopy,
                isCommentWriter: !!fCommentWriter
            }
        };

        if (props.data) data["ID"] = props.data.ID;

        if (!props.data) {
            if (!data.username) return setInfoP("Username can't be empty!");
            if (!data.firstname) return setInfoP("First name can't be empty!");
            if (!data.lastname) return setInfoP("Last name can't be empty!");
            if (!data.email) return setInfoP("E-Mail can't be empty!");
            if (!data.password) return setInfoP("Password can't be empty!");

            if (data.username.length < 8 || data.username.length > 64) return setInfoP("Username must be between 8 and 64 characters.");
            if (data.password.length < 8 || data.password.length > 64) return setInfoP("Password must be between 8 and 64 characters.");
        } else {
            if (data.password) {
                if (data.password.length < 8 || data.password.length > 64) return setInfoP("Password must be between 8 and 64 characters.");
            };
        };

        setSpinner(true);
        rpcClient({
            action: "call",
            method: `users.${props.data ? "editUser" : "addUser"}`,
            params: {
                id: data.ID,
                username: data.username,
                email: data.email,
                password: data.password,
                apiToken: data.apiToken,
                flags: data.flags,
                newFlags: data.flags,
                trackID: data.trackID,
                firstname: data.firstname,
                lastname: data.lastname
            },
            callback: res => {
                if (res.status === "ok") {
                    props.onClose();
                    curDispatch(updateTimestamp());
                } else {
                    setInfoP("Error while creating the user, check the information you provided! (Email or Username already exists?!)");
                };
                setSpinner(false);
            }
        });

    };

    React.useEffect(() => {
        if (!props.data) return;

        usernameRef.current.value = props.data.Username;
        firstnameRef.current.value = props.data.FirstName;
        lastnameRef.current.value = props.data.LastName;
        emailRef.current.value = props.data.Email;
        trackIDRef.current.value = props.data.TrackID ?? "";
        setFAdmin(props.data?.Flags?.isAdmin);
        setFCopy(props.data?.Flags?.isCopyWriter);
        setFCommentWriter(props.data?.Flags?.isCommentWriter);
    }, [props.data]);

    return <div className="route__usersList__addUser">
        <div className="route__usersList__addUser__wrap">
            <div className="route__usersList__addUser__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>{props.data ? "Edit" : "Add"} user</h3>
            <CustomInput autocomplete="off"  ref={firstnameRef} theme="dark" accent="#fff" placeholder="First name" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" ref={lastnameRef} theme="dark" accent="#fff" placeholder="Last name" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" disabled={!!props.data} ref={usernameRef} theme="dark" accent="#fff" placeholder="Username" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" ref={emailRef} theme="dark" accent="#fff" placeholder="E-Mail" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" ref={passwordRef} theme="dark" accent="#fff" placeholder="Password" type="password" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" ref={trackIDRef} theme="dark" accent="#fff" placeholder="Scale-Track User ID" type="text" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomCheckbox accent="#3F7CEA" theme="dark" placeholder="Admin" defaultValue={fAdmin} onChange={d => setFAdmin(d)} />
            <br />
            <br />
            <CustomCheckbox accent="#3F7CEA" theme="dark" placeholder="Copywriter" defaultValue={fCopy} onChange={d => setFCopy(d)} />
            <br />
            <br />
            <CustomCheckbox accent="#3F7CEA" theme="dark" placeholder="Comment writer" defaultValue={fCommentWriter} onChange={d => setFCommentWriter(d)} />

            <div className="route__usersList__addUser__wrap__btns">
                <p onClick={addUser}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__usersList__addUser__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveUser = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const curDispatch = useDispatch();

    const removeUser = () => {
        setInfoP("");

        setSpinner(true);
        rpcClient({
            action: "call",
            method: `users.deleteUser`,
            params: {
                ID: props.data.ID
            },
            callback: res => {
                if (res.status === "ok") {
                    props.onClose();
                    curDispatch(updateTimestamp());
                } else {
                    setInfoP("Error while removing the user.");
                };
                setSpinner(false);
            }
        });

    };

    return <div className="route__usersList__addUser">
        <div className="route__usersList__addUser__wrap">
            <div className="route__usersList__addUser__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Remove user</h3>
            <p>Are you sure?</p>
            <p>Removal of <span style={{ color: "#3F7CEA" }}>{props.data.Username}</span> is irreversible!</p>

            <div className="route__usersList__addUser__wrap__btns">
                <p onClick={removeUser}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__usersList__addUser__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default UsersList;
export { AddUser };