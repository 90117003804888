import React from "react";
import "./index.scss";

import moment from "moment";

import rpcClient from "../../modules/rpcClientModule";
import { useDispatch, useSelector } from "react-redux";
import { animateBox } from "../../modules/componentAnimation";
import useOnScreen from "../../modules/hooks/useOnScreen";

import CustomInput from "../../components/customComponents/CustomInput";
import Spinner from "../../components/customComponents/Spinner";
import Dropdown from "../../components/customComponents/Dropdown";
import Button from "../../components/customComponents/Button";
import FilterPanel from "../../components/customComponents/FilterPanel";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { FilteredCustomTable } from "../../components/customComponents/Table";

import { updateTimestamp } from "../../actions/timestampActions";



let curTimeout = null;
const Websites = (props) => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [offers, setOffers] = React.useState();

    const timestampRef = React.useRef();
    const searchRef = React.useRef();
    const curOnScreen = useOnScreen();

    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const timestampSelector = useSelector(state => state?.timestamp ?? null);
    const websiteStatusCodesSelector = useSelector(state => state?.types?.websiteStatusCodes ?? {});
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const websiteTrafficSourcesSelector = useSelector(state => state?.types?.websiteTrafficSources ?? []);

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;

        rpcClient({
            action: "call",
            method: "websites.getAll",
            params: {
                filters: [...filters, { name: "Type", op: "eq", value: props.type }]
            },
            callback: res => {
                if (timestampRef.current !== ts) return;
                if (res.status === "ok") {
                    if (res.data.length >= 20) setCanPaginate(true);
                };
                setData(res);
            }
        });
    };

    const continueData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (!data) return;
        if (data?.status !== "ok") return;

        rpcClient({
            action: "call",
            method: "websites.getAll",
            params: {
                filters: [
                    ...filters,
                    { name: "Type", op: "eq", value: props.type },
                    { name: "ID", op: "notIn", value: data.data.map(d => d.ID) }
                ]
            },
            callback: res => {
                if (timestampRef.current !== ts) return;
                if (res.status === "ok") {
                    if (res.data.length >= 20) setCanPaginate(true);
                    setData(d => {
                        return {
                            ...d,
                            data: [
                                ...d.data,
                                ...res.data
                            ]
                        };
                    });
                };
            }
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setFilters([{
                    or: [
                        { name: "ID", op: "like", value: searchVal },
                        { name: "Name", op: "like", value: searchVal },
                        { name: "TrafficSource", op: "like", value: searchVal }
                    ]
                }]);
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch { };
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector.advancedSearch]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [filters, timestampSelector, props.type]);

    React.useEffect(() => {
        rpcClient({
            action: "call",
            method: "offers.getAll",
            params: {limit: null},
            callback: res => {
                if (res.status === "ok") {
                    setOffers(res.data)
                } else {
                    return setOffers([])
                };
            }
        });
    }, []);

    return <div className="route__websites">
        {siteSettingsSelector?.advancedSearch ?
            <FilterPanel accent="rgb(63, 124, 234)" theme="dark" filters={[
                { name: "Name", friendlyName: "Name", type: "string" },
                { name: "Status", friendlyName: "Status", type: "custom", varType: "number", data: Object.keys(websiteStatusCodesSelector).map(key => {
                    return {text: websiteStatusCodesSelector[key], value: key}
                }) },
                { name: "TrafficSource", friendlyName: "Traffic source", type: "custom", varType: "custom", data: [
                    {text: "Any source", value: null},
                    ...websiteTrafficSourcesSelector.map(s => {
                        return {text: s, value: s};
                    })
                ] },
                { name: "Data_Info.Angle", friendlyName: "[Info] Angle", type: "string" },
                { name: "Data_Info.Persona", friendlyName: "[Info] Persona", type: "string" },
                { name: "createdAt", friendlyName: "Created at", type: "date" },
                { name: "updatedAt", friendlyName: "Updated at", type: "date" }
            ]} filterCB={f => setFilters(f)} /> :
            <>
                <CustomInput autocomplete="off" ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." />

            </>
        }        <div className="route__websites__content"> {data ? <>
            {data.status === "ok" ? <>
                {data.data.length === 0 && <p>There is nothing to display.</p>}
                {data.data.map((elem) => {
                    return <LandingItem
                        key={`${elem.ID}-${elem.updatedAt}`}
                        data={elem}
                        offers={offers}
                        canDownload={userInfoSelector?.Flags?.isAdmin}
                        onDelete={userInfoSelector?.Flags?.isAdmin ? () => {
                            return setData(d => {
                                return {
                                    ...d,
                                    data: d.data.filter(dt => dt.ID !== elem.ID)
                                };
                            });
                        } : undefined}
                        onRefresh={() => {
                            let ts = Date.now();
                            timestampRef.current = ts;
                            getData(ts);
                        }}
                        onEdit={userInfoSelector?.Flags?.isAdmin ? () => {
                            let ts = Date.now();
                            timestampRef.current = ts;
                            getData(ts);
                        } : undefined}
                    />
                })}
                {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "100%", opacity: 0, padding: "20px", margin: "20px"}}></div>}
            </> : "Server timed out!"}
        </> : <Spinner color="white" />}
        </div>
    </div>
};


const AddWebsite = (props) => {
    let [offers, setOffers] = React.useState();

    const [selectedOffer, setSelectedOffer] = React.useState();
    const [spinner, setSpinner] = React.useState();
    const [selectedFile, setSelectedFile] = React.useState();
    const [selectedTrafficSource, setSelectedTrafficSource] = React.useState();
    const [mustUseSiteGuard, setMustUseSiteGuard] = React.useState(true);
    const [selectedAngle, setSelectedAngle] = React.useState();
    const [selectedPersona, setSelectedPersona] = React.useState();
    const [errorMsg, setErrorMsg] = React.useState();
    
    const curDispatch = useDispatch();
    const websiteTrafficSourcesSelector = useSelector(state => state?.types?.websiteTrafficSources ?? []);
    
    const nameRef = React.useRef();
    const info_personaRef = React.useRef();

    const getWebsiteType = () => {
        const data = {
            "#/main/prelanding-list": 1,
            "#/main/landing-list": 2,
            "#/main/thankyou-list": 3,
            "#/main/static-list": 4
        };

        return data[window.location.hash] ?? -1
    };

    const getData = () => {
        rpcClient(
            {
                action: "call",
                method: "offers.getAll",
                params: { limit: null },
                callback: res => {
                    if (res.status === "ok") {
                        setOffers(res.data)

                    } else {
                        return setOffers()
                    };
                }
            },
        );
    };

    const uploadData = () => {
        if (!selectedOffer && getWebsiteType() !== 4) return setErrorMsg("Please select offer for website");
        if (!getWebsiteType()) return setErrorMsg("Error while getting website type");
        if (!selectedFile) return setErrorMsg("Please upload website file");
        if (!nameRef?.current?.value) return setErrorMsg("Please enter website name");
        if (!selectedTrafficSource && ![3, 4].includes(getWebsiteType())) return setErrorMsg("Traffic source can't be empty!");
        setSpinner(true);
        rpcClient(
            {
                action: "call",
                method: "websites.importFromFile",
                params: {
                    OfferID: selectedOffer,
                    Type: getWebsiteType(),
                    File: selectedFile,
                    Name: nameRef?.current?.value,
                    TrafficSource: selectedTrafficSource,
                    MustUseSiteGuard: !!mustUseSiteGuard,
                    Data_Info: {
                        Angle: selectedAngle,
                        Persona: selectedPersona
                    }
                },
                callback: res => {
                    if (res.status === "ok") {
                        setTimeout(() => {
                            curDispatch(updateTimestamp());
                        }, 300)

                        setErrorMsg()
                        props.onClose()

                    } else {
                        let msg = "There was an error while processing the website."
                        if (res.data?.file) {
                            msg +=`\nPossible file error: ${res.data.file}`
                        };
                        setErrorMsg(msg);
                    };
                }
            }
        ).finally(() => setSpinner(false));
    }

    React.useEffect(() => {
        setSelectedAngle();
        setSelectedPersona();
    }, [selectedOffer]);

    React.useEffect(() => {
        getData();
    }, [])

    return <div className="route__websites__add">

        <div className="genericModal__wrap__spinner" style={{opacity: spinner ? 1 : 0, pointerEvents: spinner ? "all" : "none"}} onClick={e => spinner && e?.stopPropagation?.()}>
            <Spinner style={{width: "32px", height: "32px", pointerEvents: "none"}} color="white" />
        </div>
        
        <p>Select offer for website</p>
        {offers && <Dropdown theme="dark" accent="rgb(150, 150, 152)" data={[
            ...(getWebsiteType() === 4 ? [
                {name: "Any offer", value: null}
            ] : []),
            ...offers.map(offer => {
                return {
                    name: `${offer?.OfferName} (Country: ${offer?.Country}, Price: ${offer?.OfferPrice}, Type: ${offer?.OfferType} )`,
                    value: offer?.ID
                }
            })
        ]} onChange={e => {
            setSelectedOffer(e.value)
        }} inlinePlaceholder="Offer" />}

        <p>Upload Website file</p>
        <input onChange={e => e.target?.files[0] && setSelectedFile(e.target.files[0])} type="file" accept=".zip" />
        <p>Enter website name</p>
        <input ref={nameRef} />

        <p>Traffic source</p>
        <Dropdown theme="dark" accent="rgb(150, 150, 152)" data={[
            ...(([3, 4].includes(getWebsiteType())) ? [
                {name: "All sources", value: null}
            ] : []),
            ...websiteTrafficSourcesSelector.map(ts => {
                return {name: ts, value: ts};
            })
        ]} onChange={e => {
            setSelectedTrafficSource(e?.value);
        }} inlinePlaceholder="Traffic source" />

        {selectedOffer && <>
            <p>[Info] Angle</p>
            <Dropdown theme="dark" accent="rgb(150, 150, 152)" data={(()=>{
                let curOffer = offers.find(o => o.ID === selectedOffer);
                if (!curOffer) return [];
                return (curOffer?.OfferAngles ?? []).map(a => {
                    return {name: a, value: a};
                });
                })()} onChange={e => {
                    setSelectedAngle(e?.value);
                }} inlinePlaceholder="[Info] Angle"
                selected={(()=>{
                    if (!selectedAngle) return null;
                    let curOffer = offers.find(o => o.ID === selectedOffer);
                    if (!curOffer) return null;

                    return curOffer.OfferAngles.includes(selectedAngle);
                })()}
            />
        </>}
        {selectedOffer && <>
            <p>[Info] Persona</p>
            <Dropdown theme="dark" accent="rgb(150, 150, 152)" data={(()=>{
                let curOffer = offers.find(o => o.ID === selectedOffer);
                if (!curOffer) return [];
                return (curOffer?.OfferPersonas ?? []).map(a => {
                    return {name: a, value: a};
                });
                })()} onChange={e => {
                    setSelectedPersona(e?.value);
                }} inlinePlaceholder="[Info] Persona"
                selected={(()=>{
                    if (!selectedPersona) return null;
                    let curOffer = offers.find(o => o.ID === selectedOffer);
                    if (!curOffer) return null;

                    return curOffer.OfferAngles.includes(selectedPersona);
                })()}
            />
        </>}

        <CustomCheckbox style={{marginTop: "20px"}} theme="dark" placeholder="Site Guard Enabled?" defaultValue={mustUseSiteGuard} onChange={e => e !== mustUseSiteGuard && setMustUseSiteGuard(!!e)} />

        <p className="route__websites__add__err">{errorMsg}</p>

        <div className="route__usersList__addUser__wrap__btns">
            <p onClick={uploadData}>
                <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                <span style={{ color: "white" }}>Yes</span>
            </p>
           <p onClick={props.onClose}>
                <span style={{ color: "white" }}>No</span>
                <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
            </p>
        </div>
    </div>

}

const LandingItem = props => {
    const mainRef = React.useRef();

    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const statusSelector = useSelector(state => state?.types?.websiteStatusCodes ?? {});

    const DeleteItem = (props2) => {
        const [infoP, setInfoP] = React.useState("");
        const [spinner, setSpinner] = React.useState(false);

        const deleteItem = () => {
            setInfoP();
            setSpinner(true);
            rpcClient(
                {
                    action: "call",
                    method: "websites.remove",
                    params: {
                        ID: props.data.ID
                    },
                    callback: res => {
                        if (res.status === "ok") {
                            props2.onClose().then(() => {
                                props2.onChange();
                            });
                        } else {
                            setInfoP("Error while deleting a website");
                        };
                        setSpinner(false)
                    }
                },
            );

        };

        return <div className="route__landingList__delete">
            <div className="route__websites__delete_wrap">
                <div className="route__websites__delete__wrap__spinner" style={{
                    opacity: spinner ? 1 : 0,
                    pointerEvents: spinner ? "all" : "none",
                    display: spinner ? "auto" : "none"
                }}>
                    <Spinner color="white" />
                </div>

                <h3>Are you sure?</h3>
                <p>Deleting the website <span style={{ color: "rgb(63, 124, 234)" }}>{props.data.Name}</span> is permanent!</p>


                <div className="route__usersList__addUser__wrap__btns">
                    <p onClick={deleteItem}>
                        <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                        <span>Save</span>
                    </p>
                    <p onClick={props2.onClose}>
                        <span>Cancel</span>
                        <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                    </p>
                </div>
                {infoP && <p style={{ marginTop: "10px", color: "rgb(234, 63, 63)" }}>{infoP}</p>}
            </div>
        </div>
    };

    return <div ref={mainRef} className="route__websites__content__landing" onClick={e => {
        e.stopPropagation();
        window.open(`${rpcClient.baseURL}/websites/preview/${props.data.ID}`, "_blank");
    }}>
        <p className="route__websites__content__landing__id" style={{marginBottom: 0}}>
            <span style={{ marginRight: "auto" }}>ID:{props.data.ID}</span>
            <span style={{marginLeft: "auto"}}>{props.data.TrafficSource ?? "any"}</span>
        </p>
        <p className="route__websites__content__landing__id" style={{paddingTop: 0}}>
            <span style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>Status: {statusSelector[props.data.Status]}
                <div style={{
                    borderRadius: "50%",
                    height: "15px",
                    width: "15px",
                    background: props.data.Status <= 10 ? "blue" : props.data.Status < 100 ? "red" : "green",
                    marginLeft: "10px"
                }}>

                </div>
            </span>
        </p>
        <img src={props.data.RenderImage ?? "/images/noimage.png"} />
        {/* <LazyImage src={`${props.data.RenderImage}`} /> */}
        <p className="route__websites__content__landing__name">
            {props.data.MustUseSiteGuard && <span style={{marginRight: "5px", color: "rgb(234, 145, 63)"}}>[Site Guard]</span>}{props.data.Name}
            <span style={{
                color: "gray",
                fontSize: 14
            }}>&nbsp;{(() => {
                if (!props.offers) return "";
                let ofr = props.offers?.find(o => o.ID === props.data.OfferID)
                if (ofr) {
                    return `${ofr.OfferName} (${ofr.Country ?? "?"}, ${ofr.OfferType ?? "?"})`
                };
                return "Any offer";
            })()}</span>

            <img src="/images/info_icon.svg" onClick={(e) => {
                e?.stopPropagation?.();
                animateBox(<DisplayAdditionalInfo data={props.data} />);
            }} style={{marginLeft: "10px"}} />
            {userSelector?.Flags?.isAdmin && <img src="/images/websiteHistory.svg" onClick={(e) => {
                e?.stopPropagation?.();
                animateBox(<WebsiteHistory ID={props.data.ID} />)
            }} />}
        </p>

        <div className="route__websites__content__landing__btns">
            {props.buttons ? props.buttons : <>
                {props.onSelect && <Button value="Select" accent="#3f7cea" onClick={e => {
                    e.stopPropagation();
                    props.onSelect?.();
                }} />}

                {(userSelector?.Flags?.isAdmin && props.onDelete) && <Button value="Remove" accent="#EA3F3F" onClick={e => {
                    e.stopPropagation();
                    animateBox(e, <DeleteItem onChange={() => {
                        if (!mainRef.current) return props.onDelete();
                        mainRef.current.animate([
                            { opacity: getComputedStyle(mainRef.current).opacity },
                            { opacity: 0 }
                        ], {
                            duration: 300,
                            iterations: 1,
                            fill: "both",
                            easing: "ease"
                        }).onfinish = () => props.onDelete();
                    }} />);
                }} />}

                {props.canDownload && <Button
                    value="Download"
                    accent="rgb(63, 124, 234)"
                    onClick={e => {
                        e.stopPropagation();
                        window.open(`${rpcClient.baseURL}/websites/download/${props.data.ID}`, "_blank");
                    }}
                />}

                {props.onEdit && <Button
                    value="Edit"
                    accent="rgb(69, 79, 91)"
                    onClick={(e) => {
                        e?.stopPropagation?.();
                        animateBox(<EditWebsite data={props.data} onChange={props.onEdit} />)
                    }}
                />}

            </>}
        </div>
    </div>
};

const EditWebsite = props => {
    const [data, setData] = React.useState();
    const [offer, setOffer] = React.useState();
    const [infoP, setInfoP] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);
    const [selectedTrafficSource, setSelectedTrafficSource] = React.useState();
    const [mustUseSiteGuard, setMustUseSiteGuard] = React.useState();
    const [ctaType, setCTAType] = React.useState();
    const [formType, setFormType] = React.useState();
    const [commentsType, setCommentsType] = React.useState();
    const [info_angle, setInfo_angle] = React.useState("");
    const [info_persona, setInfo_persona] = React.useState("");
    const [info_clientPromises, setInfo_clientPromises] = React.useState([""]);
    const [info_landerComments, setInfo_landerComments] = React.useState([""]);

    const [excludedIDs, setExcludedIDs] = React.useState([]);

    const websiteTrafficSourcesSelector = useSelector(state => state?.types?.websiteTrafficSources ?? {});

    const nameRef = React.useRef();
    const ctaTextRef = React.useRef();
    const commentsCountRef = React.useRef();
    const info_keyInfoRef = React.useRef();

    const editWebsite = () => {
        if (spinner) return;
        if (!data) return;
        if (data.status !== "ok") return;

        setInfoP("");

        let out = {
            ID: data.data.ID,
            Name: nameRef.current.value,
            TrafficSource: selectedTrafficSource,
            MustUseSiteGuard: mustUseSiteGuard,
            ExcludedIDs: excludedIDs,

            Data_Info: {
                Angle: info_angle,
                Persona: info_persona,
                ClientPromises: info_clientPromises.filter(f => f),
                KeyInformation: info_keyInfoRef.current.value,
                LanderComments: info_landerComments.filter(f => f)
            }
        };

        if (data.data.Data_Options?.hasCTA) {
            out["Options_CTA"] = {
                type: ctaType,
                text: ctaTextRef.current.value
            };
        };
        if (data.data.Data_Options.hasForm) {
            out["Options_Form"] = {
                type: formType
            };
        };
        if (data.data.Data_Options.hasComments) {
            let curCount = commentsCountRef.current.value;
            curCount = Number(curCount);
            if (isNaN(curCount)) return setInfoP("Comments count must be a number");
            if (curCount < 0) return setInfoP("Count must be greater or equals to 0");
            out["Options_Comments"] = {
                type: commentsType,
                count: curCount
            };
        };

        setSpinner(true);
        rpcClient({
            action: "call",
            method: "websites.edit",
            args: {...out},
            callback: res => {
                if (res.status === "ok") {
                    if (typeof(props.onChange) === "function") props.onChange();
                    props.onClose();
                } else {
                    setInfoP("There was an error while editing the website!");
                };
            }
        }).finally(() => setSpinner(false));
    };

    const getWebsiteType = () => {
        const data = {
            "#/main/prelanding-list": 1,
            "#/main/landing-list": 2,
            "#/main/thankyou-list": 3,
            "#/main/static-list": 4
        };

        return data[window.location.hash] ?? -1
    };

    React.useEffect(() => {
        if (data?.status !== "ok") return;

        rpcClient({
            action: "call",
            method: "offers.getAll",
            args: {
                limit: 1,
                extended: true,
                filters: [{name: "ID", op: "eq", value: data.data.OfferID}]
            },
            callback: o => {
                if (o?.status === "ok") {
                    if (o.data.length === 1) {
                        setOffer({status: "ok", data: o.data[0]});
                    } else {
                        setOffer(rpcClient.genericError);
                    };
                } else {
                    setOffer(o);
                };
            }
        })
    }, [data]);

    React.useEffect(() => {
        rpcClient({
            action: "call",
            method: "websites.getAll",
            args: {
                filters: [{name: "ID", op: "eq", value: props.data.ID}],
                extended: true,
                limit: 1
            },
            callback: d => {
                if (d.status === "ok") {
                    if (d.data.length === 1) {
                        setSelectedTrafficSource(d.data[0].TrafficSource);
                        setMustUseSiteGuard(d.data[0].MustUseSiteGuard);
                        setCTAType(d.data[0].Data_Options?.cta?.type ?? null);
                        setFormType(d.data[0].Data_Options?.form?.type ?? null);
                        setCommentsType(d.data[0].Data_Options?.comments?.type ?? null);
                        setInfo_angle(d.data[0].Data_Info?.Angle ?? "");
                        setInfo_persona(d.data[0].Data_Info?.Persona ?? "");
                        setExcludedIDs(d.data[0].Data_Options?.ExcludedIDs ?? []);

                        let cProm = d.data[0].Data_Info?.ClientPromises ?? [];
                        if (!Array.isArray(cProm)) cProm = [];
                        cProm = cProm.filter(f => f);
                        cProm.push("");
                        setInfo_clientPromises(cProm);
                        
                        let lComm = d.data[0].Data_Info?.LanderComments ?? [];
                        if (!Array.isArray(lComm)) lComm = [];
                        lComm = lComm.filter(f => f);
                        lComm.push("");
                        setInfo_landerComments(lComm);
                        
                        return setData({status: "ok", data: d.data[0]});
                    };
                    setData(rpcClient.genericError);
                } else {
                    setData(d);
                };
            }
        });
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">

            <div className="genericModal__wrap__spinner" style={{opacity: spinner ? 1 : 0, pointerEvents: spinner ? "all" : "none"}} onClick={e => spinner && e?.stopPropagation?.()}>
                <Spinner style={{width: "32px", height: "32px", pointerEvents: "none"}} color="white" />
            </div>

            <h3 style={{marginBottom: "20px"}}>Edit website</h3>

            {data ? (data.status === "ok" ? <>
            
            <CustomInput ref={nameRef} defaultValue={data.data.Name} theme="dark" accent="#fff" placeholder="Name" style={{marginBottom: "20px", width: "100%"}} />
            <Dropdown theme="dark" accent="rgb(150, 150, 152)" data={[
                ...(([3, 4].includes(getWebsiteType())) ? [
                    {name: "All sources", value: null}
                ] : []),
                ...websiteTrafficSourcesSelector.map(ts => {
                    return {name: ts, value: ts};
                })
                ]} onChange={e => {
                    setSelectedTrafficSource(e?.value);
                }} inlinePlaceholder="Traffic source"
                selected={(()=>{
                    if ([3, 4].includes(getWebsiteType())) {
                        if (selectedTrafficSource === undefined) return null;
                        if (!selectedTrafficSource) return 0;
                        return websiteTrafficSourcesSelector.indexOf(selectedTrafficSource) + 1;
                    } else {
                        if (!selectedTrafficSource) return null;
                        return websiteTrafficSourcesSelector.indexOf(selectedTrafficSource);
                    };
                })()}
                style={{marginBottom: "30px", width: "100%"}}
            />

            
            {offer?.status === "ok" && <Dropdown
                theme="dark"
                accent="rgb(150, 150, 152)"
                style={{marginBottom: "20px", width: "100%"}}
                data={offer.data.OfferAngles.map(a => {
                    return {name: <><span style={{color: "rgb(234, 145, 63)"}}>[Info] Angle: </span>{a}</>, value: a, search: a}
                })}
                inlinePlaceholder="[Info] Angle"
                onChange={e => e?.value && setInfo_angle(e?.value)}
                selected={(()=>{
                    if (offer?.status !== "ok") return;
                    if (!info_angle) return;
                    return offer.data.OfferAngles.indexOf(info_angle);
                })()}
            />}
            {offer?.status === "ok" && <Dropdown
                theme="dark"
                accent="rgb(150, 150, 152)"
                style={{marginBottom: "20px", width: "100%"}}
                data={offer.data.OfferPersonas.map(a => {
                    return {name: <><span style={{color: "rgb(234, 145, 63)"}}>[Info] Persona: </span>{a}</>, value: a, search: a}
                })}
                inlinePlaceholder="[Info] Persona"
                onChange={e => e?.value && setInfo_persona(e?.value)}
                selected={(()=>{
                    if (offer?.status !== "ok") return;
                    if (!info_persona) return;
                    return offer.data.OfferPersonas.indexOf(info_persona);
                })()}
            />}

            <CustomCheckbox style={{paddingBottom: "10px"}} theme="dark" placeholder="Site Guard Enabled?" defaultValue={mustUseSiteGuard} onChange={e => e !== mustUseSiteGuard && setMustUseSiteGuard(!!e)}  />

            {data.data.Data_Options?.hasCTA && <>
                <h3 style={{width: "100%", marginBottom: "10px", paddingTop: "20px"}}>CTA</h3>
                <Dropdown
                    theme="dark"
                    accent="rgb(150, 150, 152)"
                    data={[
                        {name: "Defined by site", value: null},
                        {name: "Orange", value: 1},
                        {name: "Green", value: 2},
                        {name: "Blue", value: 3},
                        {name: "Red", value: 4},
                    ]}
                    onChange={e => setCTAType(e?.value)}
                    selected={(()=>{
                        if (ctaType === undefined) return null;
                        switch (ctaType) {
                            case null: return 0;
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                return ctaType;
                            default: return 0;
                        };
                    })()}
                    style={{marginBottom: "20px", width: "100%"}}
                />
                <CustomInput ref={ctaTextRef} defaultValue={data.data.Data_Options?.cta?.text} theme="dark" accent="#fff" placeholder="CTA Text" style={{marginBottom: "20px", width: "100%"}} />
                {data.data.Data_Options?.CustomComponentIDs?.["cms-cta"] && data.data.Data_Options.CustomComponentIDs["cms-cta"].map(id => {
                    return <CustomCheckbox
                        defaultValue={!excludedIDs.includes(id)}
                        placeholder={id}
                        theme="dark"
                        accent="#fff"
                        style={{marginRight: "20px"}}
                        onChange={e => setExcludedIDs(eid => {
                            let tmp = [...eid];
                            if (e) {
                                if (tmp.includes(id)) tmp = tmp.filter(tmpt => tmpt !== id);
                            } else {
                                if (!tmp.includes(id)) tmp.push(id);
                            };
                            return tmp;
                        })}
                    />
                })}
            </>}

            {data.data.Data_Options?.hasForm && <>
                <h3 style={{width: "100%", marginBottom: "10px", paddingTop: "20px"}}>Form</h3>
                <Dropdown
                    theme="dark"
                    accent="rgb(150, 150, 152)"
                    data={[
                        {name: "Defined by site", value: null},
                        {name: "Generic", value: "generic"},
                        {name: "Closed gift", value: "closed-gift"}
                    ]}
                    onChange={e => setFormType(e?.value)}
                    selected={(()=>{
                        if (formType === undefined) return null;
                        if (!formType) return 0;
                        if (formType === "generic") return 1;
                        if (formType === "closed-gift") return 2;

                        return null;
                    })()}
                    style={{marginBottom: "20px", width: "100%"}}
                />
                {data.data.Data_Options?.CustomComponentIDs?.["cms-form"] && data.data.Data_Options.CustomComponentIDs["cms-form"].map(id => {
                    return <CustomCheckbox
                        defaultValue={!excludedIDs.includes(id)}
                        placeholder={id}
                        theme="dark"
                        accent="#fff"
                        style={{marginRight: "20px"}}
                        onChange={e => setExcludedIDs(eid => {
                            let tmp = [...eid];
                            if (e) {
                                if (tmp.includes(id)) tmp = tmp.filter(tmpt => tmpt !== id);
                            } else {
                                if (!tmp.includes(id)) tmp.push(id);
                            };
                            return tmp;
                        })}
                    />
                })}
            </>}

            {data.data.Data_Options?.hasComments && <>
                <h3 style={{width: "100%", marginBottom: "10px", paddingTop: "20px"}}>Comments</h3>
                <Dropdown
                    theme="dark"
                    accent="rgb(150, 150, 152)"
                    data={[
                        {name: "Defined by site", value: null},
                        {name: "Generic", value: "generic"},
                        {name: "Facebook", value: "facebook"}
                    ]}
                    onChange={e => setCommentsType(e?.value)}
                    selected={(()=>{
                        if (commentsType === undefined) return null;
                        if (!commentsType) return 0;
                        if (commentsType === "generic") return 1;
                        if (commentsType === "facebook") return 2;

                        return null;
                    })()}
                    style={{marginBottom: "20px", width: "100%"}}
                />
                <CustomInput ref={commentsCountRef} defaultValue={data.data.Data_Options?.comments?.count ?? 20} theme="dark" accent="#fff" placeholder="Comments count" style={{marginBottom: "20px", width: "100%"}} />
            </>}

            <h3 style={{width: "100%", marginBottom: "10px", paddingTop: "20px"}}>Hooks</h3>

            <p>Key information</p>
            <CustomInput defaultValue={data.data.Data_Info?.KeyInformation ?? ""} ref={info_keyInfoRef} theme="dark" accent="#fff" style={{width: "100%", marginBottom: "20px"}} />

            <p>Client promises</p>
            {info_clientPromises.map((c, cIdx) => {
                return <CustomInput theme="dark" accent="#fff" value={c} onChange={e => {
                    let val = e.target.value;
                    setInfo_clientPromises(cProm => {
                        let tmp = [...cProm];
                        tmp[cIdx] = val;
                        tmp = tmp.filter(f => f);
                        if (tmp.length < 3 && tmp[tmp.length-1]) tmp.push("");
                        if (tmp.length === 0) tmp.push("");
                        return tmp;
                    });
                }} style={{width: "100%"}} />
            })}

            <p style={{marginTop: "20px"}}>First 3 lander comments</p>
            {info_landerComments.map((c, cIdx) => {
                return <CustomInput theme="dark" accent="#fff" value={c} onChange={e => {
                    let val = e.target.value;
                    setInfo_landerComments(cProm => {
                        let tmp = [...cProm];
                        tmp[cIdx] = val;
                        tmp = tmp.filter(f => f);
                        if (tmp.length < 3 && tmp[tmp.length-1]) tmp.push("");
                        if (tmp.length === 0) tmp.push("");
                        return tmp;
                    });
                }} style={{width: "100%"}} />
            })}
                
            </> : <p>There was an error while fetching website</p>) : <Spinner style={{width: "32px", height: "32px"}} color="white" />}

            {infoP && <p className="genericModal__wrap__infoP">{infoP}</p>}

            <div className="genericModal__wrap__btns">
                {data?.status === "ok" && <p onClick={editWebsite}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>}
                <p onClick={props.onClose} style={{marginLeft: "auto"}}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

        </div>
    </div>
};

const DisplayAdditionalInfo = props => {
    let info = props.data.Data_Info;
    if (!info) info = {};
    if (typeof(info) !== "object" || Array.isArray(info)) info = {};
    let maxIdx = Object.keys(info).length - 1;

    return <div className="genericModal">
        <div className="genericModal__wrap">

            <h3 style={{width: "100%", marginBottom: "20px"}}>Additional info</h3>

            {Object.keys(info).map((key, keyIdx) => {
                return <>
                    <p className="route__websites__keyval">
                        <span>{key}:&nbsp;</span>
                        <span>{(()=>{
                            let tmp = info[key];
                            if (Array.isArray(tmp)) {
                                return tmp.map(tt => {
                                    return <>
                                        {tt}
                                        <br />
                                    </>
                                });
                            } else {
                                return tmp;
                            };
                        })()}</span>
                    </p>
                    {keyIdx !== maxIdx && <br />}
                </>
            })}

            <div className="genericModal__wrap__btns">
                <p onClick={props.onClose} style={{marginLeft: "auto"}}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>
        </div>
    </div>
};

const WebsiteHistory = props => {
    const [data, setData] = React.useState();

    const prepareDiff = ({diff}) => {
        let added = [];
        let changed = [];
        let removed = [];

        const getValue = item => {
            if (item === null || item === undefined || item === "") return "*empty*";
            if (Array.isArray(item)) {
                return item.join(", ");
            };
            if (typeof(item) === "object") {
                try {
                    return JSON.stringify(item);
                } catch {
                    return String(item);
                };
            };
            return String(item);
        };

        for (let key of Object.keys(diff.added)) {
            added.push(<span className="route__websites__history__div__diff route__websites__history__div__diff--added"><span>Added {key}: </span>{`${getValue(diff.added[key])}`}</span>);
        };
        for (let key of Object.keys(diff.changed)) {
            changed.push(<span className="route__websites__history__div__diff route__websites__history__div__diff--changed"><span>Changed {key}: </span>{`${getValue(diff.changed[key].old)} -> ${getValue(diff.changed[key].new)}`}</span>);
        };
        for (let key of Object.keys(diff.removed)) {
            removed.push(<span className="route__websites__history__div__diff route__websites__history__div__diff--removed"><span>Removed {key}: </span>{`${getValue(diff.removed[key])}`}</span>);
        };

        return <div className="route__websites__history__div">
            {added}
            {changed}
            {removed}
        </div>;
    };

    React.useEffect(() => {
        rpcClient({
            action: "call",
            method: "websites.history",
            params: {ID: props.ID},
            callback: setData
        });
    }, []);

    return <div className="route__websites__history">
        <div className="route__websites__history__wrap">
            <div className="route__websites__history__wrap__top">
                <h3>Website history</h3>
                <div className="route__websites__history__wrap__top__btns">
                    <p onClick={props.onClose}><span>Close</span><img src="/images/closeBtn.png" /></p>
                </div>
            </div>
            <div className="route__websites__history__wrap__bottom">
                <FilteredCustomTable
                    accent="#48515C"
                    theme="dark"
                    headers={["User", "Date", "Changes"]}
                    customColumns={(new Array(3)).fill("max-content")}
                    style={{columnGap: "40px"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner"}]];
                        if (data.status !== "ok") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data!"}]];

                        let out = [];
                        for (let item of data.data) {
                            out.push([
                                {keyID: item.ID, type: "text", text: `${item._User?.FirstName} ${item._User?.LastName} (${item._User?.Username})`},
                                {keyID: item.ID, type: "text", text: moment(item.Date).toDate().toLocaleString()},
                                {keyID: item.ID, type: "custom", data: item.created ? "Uploaded the website" : prepareDiff(item)}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

export default Websites;
export { AddWebsite, LandingItem as WebsiteItem }