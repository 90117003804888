import axios from "axios";

let curStore = null;

export const setStore = st => curStore = st;
export const getStore = () => curStore;

export const setCSRF = () => {
  return axios({
    url: `${backendURL}/api/csrf`,
    method: "PUT",
    ...axiosConfig,
  })
    .then((res) => (axios.defaults.headers.common["X-XSRF-TOKEN"] = res.data))
    .catch(() => { });
};

const stringDecoder = str => {
  try {
    return decodeURI(str);
  } catch {
    return str;
  }
}

export const parseData = (data) => {
  if (data === null || data === undefined) return data;
  switch (typeof data) {
    case "string": return stringDecoder(data);
    case "number": return Number(data);
    case "object":
      if (data instanceof Array) {
        return data.map(item => parseData(item));
      } else {
        let final = {};
        Object.keys(data).forEach(item => {
          final[item] = parseData(data[item]);
        });
        return final;
      };
    case "boolean": return (String(data) === "true") ? true : false;
    default: return data;
  };
};

export const setAxiosDataHandler = () => {
  axios.interceptors.response.use((res) => {
    if (res.data.status === "ok") {
      res.data.data = parseData(res.data.data);
      return res;
    } else {
      return res;
    };
  }, err => {
    return Promise.reject(err);
  })
};

export const backendURL = "http://lvh.me:5000";
//export const backendURL = "https://backend.scale-crm.com:5000";
export const axiosConfig = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "http://lvh.me:3000",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  },
};
export const genericError = { status: "error", data: "SERVER_ERROR" };