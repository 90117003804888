import React from "react";
import "./index.scss";

import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import { animateBox } from "../../../modules/componentAnimation";
import rpcClient from "../../../modules/rpcClientModule";

import CustomInput from "../../customComponents/CustomInput";
import Spinner from "../../customComponents/Spinner";

const ImageSelectModal = props => {
    const [images, setImages] = React.useState();
    const [search, setSearch] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const timestampRef = React.useRef();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;

        setCanPaginate(false);

        rpcClient({
            action: "call",
            method: "staticContent.images.getAll",
            params: {
                filters: [
                    {name: "Tag", op: "eq", value: props.tag}
                ],
                orders: [
                    {name: "createdAt", order: "desc"}
                ]
            },
            callback: d => {
                if (timestampRef.current !== ts) return;
                if (d.status === "ok") {
                    if (d.data.length >= 20) setCanPaginate(true);
                };
                setImages(d);
            }
        });
    };

    const continueData = (ts) => {
        if (!images) return;
        if (images?.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        
        setCanPaginate(false);

        rpcClient({
            action: "call",
            method: "staticContent.images.getAll",
            params: {
                filters: [
                    {name: "Tag", op: "eq", value: props.tag},
                    {name: "ID", op: "notIn", value: images.data.map(i => i.ID)}
                ],
                orders: [
                    {name: "createdAt", order: "desc"}
                ]
            },
            callback: d => {
                if (timestampRef.current !== ts) return;
                if (d.status === "ok") {
                    if (d.data.length >= 20) setCanPaginate(true);
                    setImages(i => {
                        return {
                            ...i,
                            data: [
                                ...i.data,
                                ...d.data
                            ]
                        };
                    });
                };
            }
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [search]);

    return <div className="modals__imageSelectModal">
        <div className="modals__imageSelectModal__head">
            <CustomInput
                theme="dark"
                accent="#fff"
                placeholder="Search..."
                onChange={e => {
                    let val = e.target.value;
                    curDefer(() => setSearch(val))
                }}
            />

            <p onClick={() => {
                animateBox(<UploadImage tag={props.tag} onChange={() => {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }} />)
            }}>
                <img style={{ marginRight: "15px" }} src="/images/addBtn.png" />
                <span>Upload</span>
            </p>

            <p onClick={props.onClose}>
                <span>Close</span>
                <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
            </p>
        </div>

        <div className="modals__imageSelectModal__content">
            {images ? (images.status === "ok" ? <>
                {images.data.map(img => {
                    return <div className="modals__imageSelectModal__content__img" onClick={() => {
                        if (typeof(props.onChange) === "function") {
                            if (props.extended) {
                                props.onChange(img);
                            } else {
                                props.onChange(img.URL);
                            };
                        };
                        props.onClose();
                    }}>
                        <img src={img.URL} />
                        <p>{img.ImageName}</p>
                    </div>
                })}

                {canPaginate && <div ref={curOnScreen.measureRef} style={{opacity: 0, width: "100%"}}></div>}
            </> : <p>There was an error while fetching images</p>) : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
        </div>
    </div>
};

const UploadImage = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [curImage, setCurImage] = React.useState();
    const [selectedFile, setSelectedFile] = React.useState();
    const [infoP, setInfoP] = React.useState("");

    const nameRef = React.useRef();

    const onFileSelected = e => {
        if (e.target.files.length === 0) return;
        setSelectedFile(e.target.files[0]);

        if (!nameRef.current.value) nameRef.current.value = e.target.files[0].name;

        let fileReader = new FileReader();
        fileReader.onload = fr => {
            setCurImage(fr.target.result);
        };
        fileReader.readAsDataURL(e.target.files[0]);
    };

    const uploadImage = () => {
        setInfoP("");

        let data = {
            ImageName: nameRef.current.value,
            ImageTag: props.tag ?? null,
            ImageFile: selectedFile
        };
        if (!data.ImageName) return setInfoP("Name can't be empty");
        if (!data.ImageFile) return setInfoP("Select an image to be uploaded");

        setSpinner(true);
        rpcClient({
            action: "call",
            method: "staticContent.images.upload",
            args: {...data},
            callback: d => {
                if (d.status === "ok") {
                    if (typeof(props.onChange) === "function") props.onChange(d.data);
                    props.onClose();
                } else {
                    return setInfoP("There was an error while uploading the image!");
                };
            }
        }).finally(() => setSpinner(false));
    };

    return <div className="genericModal modals__imageSelectModal__upload">
        <div className="genericModal__wrap">

            <div className="genericModal__wrap__spinner" style={{opacity: spinner ? 1 : 0, pointerEvents: spinner ? "all" : "none"}}>
                <Spinner color="white" style={{pointerEvents: "none"}} />
            </div>

            <h3 style={{marginBottom: "20px"}}>Upload image</h3>

            <CustomInput ref={nameRef} theme="dark" accent="#fff" placeholder="Image name" style={{marginBottom: "20px", width: "100%"}} />
            <div className="modals__imageSelectModal__upload__image" style={{marginBottom: "20px"}} onClick={e => {
                e?.target?.querySelector?.("input")?.click?.();
            }}>
                {!curImage && <p>Click to select image</p>}
                <img src={curImage ?? ""} />
                <input type="file" accept="image/*" onChange={onFileSelected} />
            </div>

            {infoP && <p className="genericModal__wrap__infoP">{infoP}</p>}

            <div className="genericModal__wrap__btns">
                <p onClick={uploadImage}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Upload</span>
                </p>

                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

        </div>
    </div>
};

export default ImageSelectModal