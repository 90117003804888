import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";
import { updateTimestamp } from "../../actions/timestampActions";

import CustomInput from "../../components/customComponents/CustomInput";
import Button from "../../components/customComponents/Button";
import Spinner from "../../components/customComponents/Spinner";
import rpcClient from "../../modules/rpcClientModule";

const Login = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const mainNavigate = useNavigate();
    const mainDispatch = useDispatch();

    const userRef = React.useRef();
    const passwordRef = React.useRef();

    const animateNavigate = to => {
        return animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            login();
        }
    };

    const login = () => {
        setInfoP();

        let data = {
            username: userRef.current.value,
            password: passwordRef.current.value
        };

        if (!data.username || !data.password) return setInfoP("Username or password can't be empty");

        setSpinner(true);
        rpcClient({
            action: "call",
            method: "auth.login",
            params: {
                username: userRef.current.value,
                password: passwordRef.current.value
            },
            callback: res => {
                if (res.status === "ok") {
                    animateNavigate("/").then(() => mainDispatch(updateTimestamp()));
                } else {
                    setSpinner(false);
                    setInfoP("Username or password is incorrect!");
                };
            }
        });

    };

    return <div className="route__login" style={{ backgroundImage: 'url("/images/bcg.png")' }}>
        <div className="route__login__wrap">
            <p>Welcome to the CMS system</p>
            <CustomInput autocomplete="off" ref={userRef} placeholder="Username" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: "24px" }} />
            <CustomInput onKeyDown={handleKeyDown} autocomplete="off" ref={passwordRef} placeholder="Password" accent="#FFF" theme="dark" type="password" style={{ width: "100%" }} />
            <div className="route__login__wrap__btn">
                {spinner ? <Spinner color="#3F7CEA" /> : <Button accent="#3F7CEA" theme="dark" value="Login" style={{ width: "100%" }} onClick={login} />}
            </div>
            {infoP && <p className="route__login__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default Login;