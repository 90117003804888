import React from "react";
import "./index.scss";

import { animateBox } from "../../modules/componentAnimation";
import { countries } from "../../modules/countryModule";
import rpcClientModule from "../../modules/rpcClientModule";
import useDefer from "../../modules/hooks/useDefer";

import Spinner from "../../components/customComponents/Spinner";
import Dropdown from "../../components/customComponents/Dropdown";
import CustomInput from "../../components/customComponents/CustomInput";

const Localization = () => {
    const [localizationKeys, setLocalizationKeys] = React.useState();
    const [localizationRoots, setLocalizationRoots] = React.useState();
    const [search, setSearch] = React.useState("");

    const getMaxCountries = () => {
        let max = 0;
        for (let key of Object.keys(localizationRoots.data)) {
            if (localizationRoots.data[key].length > max) {
                max = localizationRoots.data[key].length;
            };
        };

        return max;
    };

    const checkKeyIsLocalized = key => {
        let curKey = localizationKeys.data[key];
        let curRoot = localizationRoots.data[key];
        let maxCountries = getMaxCountries();

        if (!curRoot?.length) return false;
        if (curRoot.length < maxCountries) return false;
        for (let item of curRoot) {
            if (item?.Count < curKey?.length) return false;
        };
        return true;
    };

    const checkCountryHasLocalization = (key, count) => {
        let curRoots = localizationKeys.data[key];
        if (curRoots.length > count) return false;
        return true;
    };

    const getData = () => {
        rpcClientModule([
            {
                action: "call",
                method: "localizations.getKeyTypes",
                callback: setLocalizationKeys
            },
            {
                action: "call",
                method: "localizations.getRoots",
                callback: setLocalizationRoots
            }
        ]);
    };

    React.useEffect(() => {
        getData();  
    }, []);

    return <div className="route__localization">
        <CustomInput placeholder="Search" theme="dark" className="route__localization__search" onChange={e => setSearch(e?.target?.value)} />
        {(localizationKeys && localizationRoots) ? <>
            {(localizationKeys.status === "ok" && localizationRoots.status === "ok") ? <>
            {Object.keys(localizationRoots.data).map(key => {
                if (search) {
                    if (!key.toLowerCase().includes(search.toLocaleLowerCase())) return null;
                };
                return <div className={`route__localization__key ${checkKeyIsLocalized(key) ? "" : "route__localization__key--yellow"}`} onClick={() => {
                    animateBox(<EditLocalization
                        onChange={() => getData()}
                        localization={key}
                        availableKeys={localizationKeys.data}
                    />);
                }}>
                    <span>{key}</span>
                    <div className="route__localization__key__countries">
                        {localizationRoots.data[key].map(c => <>
                            <img src={`/images/countryFlags/${String(c.Country).toLowerCase()}.png`} />
                            <span className={`${checkCountryHasLocalization(key, c.Count) ? "route__localization__key__countries--green" : "route__localization__key__countries--yellow"}`}>{c.Count}</span>
                        </>)}
                    </div>
                </div>
            })}
            </> : <p>There was an error while fetching data!</p>}
        </> : <Spinner color="white" />}
    </div>
};

const EditLocalization = props => {
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [data, setData] = React.useState();
    const [infoP, setInfoP] = React.useState("");

    const newDataRef = React.useRef({});
    const curDefer = useDefer();

    const editLocalizations = () => {
        if (!newDataRef.current) return;
        if (!selectedCountry) return;
        if (Object.keys(newDataRef.current).length === 0) return;
        
        let tmp = [];
        for (let key of Object.keys(newDataRef.current)) {
            tmp.push({
                Key: key,
                Country: selectedCountry,
                Value: newDataRef.current[key]
            });
        };
        if (tmp.length === 0) return;

        setSpinner(true);
        rpcClientModule({
            action: "call",
            method: "localizations.add",
            params: { Values: tmp },
            callback: d => {
                setSpinner(false);
                setInfoP("");
                if (d.status === "error") {
                    return setInfoP("There was an error while saving the localizations");
                };

                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
            }
        });
    };

    React.useEffect(() => {
        if (!selectedCountry) return;

        newDataRef.current = {};
        setData();
        
        curDefer(() => {
            rpcClientModule({
                action: "call",
                method: "localizations.get",
                args: { Key: props.localization, Country: selectedCountry },
                callback: setData
            })
        }, 0);
    }, [selectedCountry]);

    return <div className="genericModal">
        <div className="genericModal__wrap">

            <div className="genericModal__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e?.stopPropagation()}>
                <Spinner color="white" />
            </div>

            <h3>Edit localization <span style={{color: "rgb(63, 124, 234)"}}>{props.localization}</span></h3>

            <Dropdown
                theme="dark"
                accent="#fff"
                inlinePlaceholder="Country"
                data={countries.map(c => {
                    return {name: <><span style={{color: "rgb(234, 145, 63)"}}>Country: </span>{c.name}</>, value: c.code, search: c.name}
                })}
                onChange={e => selectedCountry !== e?.value && setSelectedCountry(e?.value)}
                selected={(()=>{
                    if (!selectedCountry) return null;
                    return countries.indexOf(countries.find(c => c.code === selectedCountry));
                })()}
                style={{marginTop: "20px", marginBottom: "20px"}}
            />

            {selectedCountry && (data ? (data?.status === "ok" ? <>
                {props.availableKeys[props.localization].map(key => {
                    return <CustomInput
                        key={`${selectedCountry}.${props.localization}.${key}`}
                        theme="dark"
                        accent="#fff"
                        placeholder={`${props.localization}.${key}`}
                        style={{width: "100%", marginBottom: "20px"}}
                        defaultValue={data.data[`${props.localization}.${key}`] ?? ""}
                        onChange={e => newDataRef.current[`${props.localization}.${key}`] = e.target.value}
                    />
                })}
            </> : <p>There was an error while fetching data</p>) : <Spinner color="white" />)}

            {infoP && <p className="genericModal__wrap__infoP">{infoP}</p>}

            <div className="genericModal__wrap__btns">
                {selectedCountry && <p onClick={editLocalizations}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>}
                <p onClick={props.onClose} style={{marginLeft: "auto"}}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>
        </div>
    </div>
};

export default Localization;