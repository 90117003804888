import React from "react";

import rpcClient from "../../modules/rpcClientModule";

const Test = () => {
    const [file, setFile] = React.useState();

    const uploadFile = () => {
        if (!file) return;

        rpcClient({
            action: "call",
            method: "websites.importFromFile",
            params: {
                File: file,
                Name: "test",
                OfferID: "1efe1d43-1589-4def-b239-d3b3c1d1c917",
                Type: 2
            },
            callback: d => console.log(d)
        });
    };

    return <div>
        <input type="file" onChange={e => setFile(e?.target?.files?.[0])} />
        <input type="button" value="Upload" onClick={() => uploadFile()} />
    </div>
};

export default Test;