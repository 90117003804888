import React from "react";
import "./index.scss";

import rpcClient from "../../modules/rpcClientModule";
import { useDispatch, useSelector } from "react-redux";
import { animateBox } from "../../modules/componentAnimation";
import useOnScreen from "../../modules/hooks/useOnScreen";

import CustomInput from "../../components/customComponents/CustomInput";
import Spinner from "../../components/customComponents/Spinner";
import Dropdown from "../../components/customComponents/Dropdown";
import Button from "../../components/customComponents/Button";
import FilterPanel from "../../components/customComponents/FilterPanel";

import { updateTimestamp } from "../../actions/timestampActions";

let curTimeout = null;
const WebsitesToCheck = (props) => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [offers, setOffers] = React.useState();

    const curOnScreen = useOnScreen();

    const timestampRef = React.useRef();
    const paginationOffset = React.useRef();
    const searchRef = React.useRef();

    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const timestampSelector = useSelector(state => state?.timestamp ?? null);
    const websiteTypesSelector = useSelector(state => state?.types?.websiteTypes ?? {});
    const websiteStatusCodesSelector = useSelector(state => state?.types?.websiteStatusCodes ?? {});

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;

        rpcClient({
            action: "call",
            method: "websites.getAll",
            params: {
                filters: [...filters, { name: "Status", op: "leq", value: 99 }],
                orders: [{name: "Status", order: "asc"}, {name: "createdAt", order: "desc"}]
            },
            callback: res => {
                if (timestampRef.current !== ts) return;
                if (res.status === "ok") {
                    if (res.data.length >= 20) {
                        setCanPaginate(true);
                    } else {
                        setCanPaginate(false);
                    };
                };
                setData(res);
            }
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data?.status !== "ok") return;
        if (timestampRef.current !== ts) return;

        rpcClient({
            action: "call",
            method: "websites.getAll",
            params: {
                offset: paginationOffset.current,
                filters: [...filters, { name: "Type", op: "eq", value: props.type }],
                orders: [{name: "Status", order: "asc"}, {name: "createdAt", order: "desc"}]
            },
            callback: res => {
                if (timestampRef.current !== ts) return;
                if (res.status === "ok") {
                    if (res.data.length >= 20) setCanPaginate(true);

                    setData(d => {
                        return {
                            ...d,
                            data: [
                                ...d.data,
                                ...res.data
                            ]
                        };
                    });
                };
            }
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [curOnScreen.measureRef, canPaginate]);

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setFilters([{
                    or: [
                        { name: "ID", op: "like", value: searchVal },
                        { name: "Name", op: "like", value: searchVal }
                    ]
                }]);
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch { };
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector.advancedSearch]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [filters, timestampSelector, props.type]);

    React.useEffect(() => {
        rpcClient({
            action: "call",
            method: "offers.getAll",
            params: {},
            callback: res => {
                if (res.status === "ok") {
                    setOffers(res.data);
                } else {
                    return setOffers([]);
                };
            }
        });
    }, []);

    return <div className="route__websites">
        {siteSettingsSelector?.advancedSearch ?
            <FilterPanel accent="rgb(63, 124, 234)" theme="dark" filters={[
                { name: "Name", friendlyName: "Name", type: "string" },
                { name: "Type", friendlyName: "Type", type: "custom", varType: "string", data: Object.keys(websiteTypesSelector).map(key => {
                    return {text: websiteTypesSelector[key], value: key}
                }) },
                { name: "Status", friendlyName: "Status", type: "custom", varType: "number", data: Object.keys(websiteStatusCodesSelector).map(key => {
                    return {text: websiteStatusCodesSelector[key], value: key}
                }) },
                { name: "createdAt", friendlyName: "Created at", type: "date" },
                { name: "updatedAt", friendlyName: "Updated at", type: "date" }
            ]} filterCB={f => setFilters(f)} /> :
            <>
                <CustomInput autocomplete="off" ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." />

            </>
        }        <div className="route__websites__content"> {data ? <>
            {data.status === "ok" ? <>
                {data.data.length === 0 && <p>There is nothing to display.</p>}
                {data.data.map((elem) => {
                    return <LandingItem
                        key={`${elem.ID}-${elem.updatedAt}`}
                        data={elem}
                        offers={offers}
                        onUpdate={() => {
                            return setData(d => {
                                return {
                                    ...d,
                                    data: d.data.filter(dt => dt.ID !== elem.ID)
                                };
                            });
                        }}
                        onRefresh={() => {
                            let ts = Date.now();
                            timestampRef.current = ts;
                            getData(ts);
                        }}
                    />
                })}
                {canPaginate && <div ref={curOnScreen.measureRef} style={{opacity: 0, width: "100%", padding: "20px", margin: "20px"}}></div>}
            </> : "Server timed out!"}
        </> : <Spinner color="white" />}
        </div>
    </div>
};




const LandingItem = props => {
    const mainRef = React.useRef();

    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const statusSelector = useSelector(state => state?.types?.websiteStatusCodes ?? {});


    return <div ref={mainRef} className="route__websites__content__landing" onClick={e => {
        e.stopPropagation();
        window.open(`${rpcClient.baseURL}/websites/preview/${props.data.ID}`, "_blank");
    }}>
        <p className="route__websites__content__landing__id">
            <span style={{ marginLeft: "auto" }}>ID:{props.data.ID}</span>
            
        </p>
        <p className="route__websites__content__landing__id">
            <span style={{ marginLeft: "auto", display:"flex", alignItems:"center" }}>Status: {statusSelector[props.data.Status]}
                <div style={{
                    borderRadius: "50%",
                    height: "15px",
                    width: "15px",
                    background: props.data.Status<=10?"blue":props.data.Status<100?"red":"green",
                    marginLeft:"10px"
                }}>

                </div>
            </span>
        </p>
        <img src={props.data.RenderImage ?? "/images/noimage.png"} />
        {/* <LazyImage src={`${props.data.RenderImage}`} /> */}
        <p className="route__websites__content__landing__name">
            {props.data.Name}
            <span style={{
                color: "gray",
                fontSize: 14
            }}>&nbsp;({(() => {
                if (!props.offers) return "";
                let ofr = props.offers?.find(o => o.ID === props.data.OfferID)
                if (ofr) {
                    return `${ofr.OfferName}, ${ofr.Country}`
                }
            })()})</span>

        </p>

        <div className="route__websites__content__landing__btns">
            {props.buttons ? props.buttons : <>
                {props.onSelect && <Button value="Select" accent="#3f7cea" onClick={e => {
                    e.stopPropagation();
                    props.onSelect?.();
                }} />}

                <Button value="Edit status" accent="#3F7CEA" onClick={e => {
                    e.stopPropagation();
                    animateBox(e, <WebsiteStatusChange data={props.data} onChange={() => {
                        if (!mainRef.current) return props.onUpdate();
                        mainRef.current.animate([
                            { opacity: getComputedStyle(mainRef.current).opacity },
                            { opacity: 0 }
                        ], {
                            duration: 300,
                            iterations: 1,
                            fill: "both",
                            easing: "ease"
                        }).onfinish = () => props.onDelete();
                    }} />);
                }} />

            </>}
        </div>
    </div>
};


const WebsiteStatusChange = (props) => {
    const [data, setData] = React.useState();
    const [selectedStatus, setSelectedStatus] = React.useState();
    const [errorMsg, setErrorMsg] = React.useState("");
    const curDispatch = useDispatch();

    const errorSelector = useSelector(state => state?.types?.websiteStatusCodes ?? {});

    const editSiteStatus = () => {
        rpcClient({
            action: "call",
            method: "websites.editStatus",
            params: {
                ID: props.data.ID,
                newStatus: selectedStatus
            },
            callback: res => {
                if (res.status === "ok") {
                    curDispatch(updateTimestamp());
                    props.onClose();
                } else {
                    setErrorMsg("An error occurred while saving the status")
                }
            }
        })
    }


    return <div className="route__websites__editStatus">
        <p>Select website status:</p>
        <Dropdown accent={"#2F363E"} theme={"dark"} selected={0} data={Object.keys(errorSelector).filter(e => Number(e) >= 90).map(elem => {
            return { name: errorSelector[elem], value: elem }
        })} onChange={e => {
            setSelectedStatus(e.value)
        }} />
        <div className="route__websites__editStatus__buttons">
            <button onClick={editSiteStatus}>Save</button>
            <button onClick={props.onClose}>Cancel</button>
        </div>
        <p>{errorMsg}</p>
    </div>
}




export default WebsitesToCheck;