const initialState = {};

module.exports = (state = initialState, action) => {
    switch (action.type) {
        case "TYPES_UPDATE":
            return {
                ...action.payload
            };
        default: return state;
    };
};