export default async () => {
    // add axios
    try {
        if (!this.axios) {
            this.axios = (await import("axios")).default;
        };
    } catch {
        // no this
        if (!window.axios) {
            window.axios = (await import("axios")).default;
        };
    };
};