import React from "react";
import "./index.scss";

import rpcClient from "../../modules/rpcClientModule";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as timestampActions from "../../actions/timestampActions";
import * as userDataActions from "../../actions/userDataActions";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../customComponents/Spinner";
import CustomInput from "../customComponents/CustomInput";
import { animateBox } from "../../modules/componentAnimation";

const Sidebar = () => {
    const mainNavigate = useNavigate();
    const mainLocation = useLocation();
    const mainDispatch = useDispatch();
    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

    const [selected, setSelected] = React.useState("");
    const [modalVisible, setModalVisible] = React.useState();
    const [modalSpinner, setModalSpinner] = React.useState();

    const modalRef = React.useRef();

    const animateNavigate = to => animateModule(mainNavigate, to, document.querySelector(".component__mainContent"));

    const logOut = () => {
        setModalVisible();
        setModalSpinner(true);


        rpcClient({
            action: "call",
            method: "auth.logout",
            callback: res => {
                if (res.status === "ok") {
                    mainDispatch(userDataActions.setUserData(false, null));
                    mainDispatch(timestampActions.updateTimestamp());
                };
                setModalSpinner(false)
            }
        });
    };

    React.useEffect(() => {
        if (!selected) return;
        if (selected === "campaign") return;
        if (mainLocation.pathname.startsWith(`/main/${selected}`)) return;
        animateNavigate(`/main/${selected}`);
    }, [selected]);

    React.useEffect(() => {
        if (modalVisible) {
            if (!modalRef.current) return;
            modalRef.current.style.top = `${modalVisible.y}px`;
            modalRef.current.style.left = `${modalVisible.x}px`;
        };
    }, [modalVisible]);

    React.useEffect(() => {
        if (!selected) {
            if (mainLocation.pathname.startsWith("/main")) {
                let tmp = mainLocation.pathname.replace("/main/", "");
                tmp = tmp.split("/")[0];
                setSelected(tmp);
            };
        };
        if (mainLocation.pathname.startsWith("/main/campaign/")) {
            if (selected !== "campaign") setSelected("campaign");
        }
    }, [mainLocation.pathname]);

    React.useEffect(() => {
        let handler = e => {
            e.stopPropagation();
            if (modalVisible) setModalVisible();
        };

        document.body.addEventListener("click", handler);

        return () => {
            try {
                document.body.removeEventListener("click", handler);
            } catch { };
        };
    }, [modalVisible]);


    return <div className="component__sidebar">
        <div className="component__sidebar__modal" ref={modalRef} style={{
            opacity: modalVisible ? 1 : 0,
            pointerEvents: modalVisible ? "all" : "none",
        }}>
            <p onClick={(e) => {
                setModalSpinner(true);
                animateBox(e, <ChangePassword onChange={() => setModalSpinner(false)} />);
            }}><img src="/images/logOut.png" /> <span>Change password</span></p>

            <p onClick={() => {
                setModalSpinner(true);
                logOut();
            }}><img src="/images/logOut.png" /> <span>Log out</span></p>
        </div>


        <p onClick={() => setSelected("campaign-list")} className={`component__sidebar__item ${selected === "campaign-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/campaignIcon.png" /> Campaign list</p>
        <p onClick={() => setSelected("domain-list")} className={`component__sidebar__item ${selected === "domain-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/domainIcon.png" /> Domain list</p>
        <div className="component__sidebar__itemCollapsed" onClick={e => e.currentTarget.classList.toggle("component__sidebar__itemCollapsed--active")}>
            <img src="/images/websiteGroup.png" />
            <p>Website groups</p>
            <img src="/images/downArrow.png" className="component__sidebar__itemCollapsed__arrow" />

            <div className="component__sidebar__itemCollapsed__children">
                <p onClick={(e) => { e.stopPropagation(); setSelected("prelanding-list") }} className={`component__sidebar__item ${selected === "prelanding-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/prelanderIcon.png" /> Pre-landing list</p>
                <p onClick={(e) => { e.stopPropagation(); setSelected("landing-list") }} className={`component__sidebar__item ${selected === "landing-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/landerIcon.png" /> Landing (offer) list</p>
                <p onClick={(e) => { e.stopPropagation(); setSelected("thankyou-list") }} className={`component__sidebar__item ${selected === "thankyou-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/thankyouIcon.png" /> Thankyou list</p>
                <p onClick={(e) => { e.stopPropagation(); setSelected("static-list") }} className={`component__sidebar__item ${selected === "static-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/wwwIcon.png" /> Static list</p>
            </div>
        </div>
        <p onClick={() => setSelected("asset-list")} className={`component__sidebar__item ${selected === "asset-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/assetsImg.png" /> Images</p>
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("localization")} className={`component__sidebar__item ${selected === "localization" ? "component__sidebar__item--active" : ""}`}><img src="/images/translationIcon.svg" /> Localization</p>}
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("users-list")} className={`component__sidebar__item ${selected === "users-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/usersIcon.png" /> Users</p>}
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("api-tokens-list")} className={`component__sidebar__item ${selected === "api-tokens-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/apiTokensIcon.svg" /> API Tokens</p>}
        {userDataSelector?.UserInfo?.Flags?.isCopyWriter && <p onClick={() => setSelected("copy-writer")} className={`component__sidebar__item ${selected === "copy-writer" ? "component__sidebar__item--active" : ""}`}><img src="/images/websiteGroup.png" /> Approve new websites</p>}
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("allowed-sites")} className={`component__sidebar__item ${selected === "allowed-sites" ? "component__sidebar__item--active" : ""}`}><img src="/images/websiteGroup.png" /> Allowed websites </p>}
        {userDataSelector?.UserInfo?.Flags?.isCommentWriter && <p onClick={() => setSelected("user-comments")} className={`component__sidebar__item ${selected === "user-comments" ? "component__sidebar__item--active" : ""}`}><img src="/images/createComment.png" /> User comments</p>}
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("admin-tools")} className={`component__sidebar__item ${selected === "admin-tools" ? "component__sidebar__item--active" : ""}`}><img src="/images/usersIcon.png" /> Admin tools</p>}


        <p className="component__sidebar__userInfo" onClick={e => {
            e.stopPropagation();
            if (modalSpinner) return;
            if (modalVisible) {
                setModalVisible();
            } else {
                setModalVisible({ x: e.pageX, y: e.pageY });
            };
        }}>
            <div className="component__sidebar__userInfo__spinner" style={{
                opacity: modalSpinner ? 1 : 0,
                pointerEvents: modalSpinner ? "all" : "none"
            }} onClick={e => modalSpinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" style={{ width: "32px", height: "32px" }} />
            </div>

            <p className="component__sidebar__userInfo__circle">{userDataSelector?.UserInfo?.Username[0]?.toUpperCase()}</p>
            <p className="component__sidebar__userInfo__name">{userDataSelector?.UserInfo?.Username}</p>
            <img className="component__sidebar__userInfo__moreInfo" src="/images/moreActionsBtn.png" />
        </p>
    </div>
};

const ChangePassword = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const oldPasswordRef = React.useRef();
    const newPasswordRef = React.useRef();
    const confirmPasswordRef = React.useRef();

    const changePassword = () => {
        setInfoP("");

        if (newPasswordRef.current.value !== confirmPasswordRef.current.value) return setInfoP("Confirm password doesn't match the New password!");

        let data = {
            oldPassword: oldPasswordRef.current.value,
            newPassword: newPasswordRef.current.value
        };
        if (!data.oldPassword) return setInfoP("Old password can't be empty!");
        if (!data.newPassword) return setInfoP("New password | Confirm new password can't be empty!");
        if (data.newPassword.length < 8 || data.newPassword.length > 64) return setInfoP("New password must be between 8 and 64 characters!");

        setSpinner(true);

        rpcClient({
            action: "call",
            method: "users.changePassword",
            params: {
                oldPassword: oldPasswordRef.current.value,
                newPassword: newPasswordRef.current.value
            },
            callback: res => {
                setSpinner(false);
                if (res.status === "ok") {
                    onClose();
                } else {
                    setInfoP("Error while changing password! Old password is invalid.");
                };
            }
        });
    };

    const onClose = () => {
        props.onChange && props.onChange();
        props.onClose();
    };

    return <div className="component__sidebar__changePassword">
        <div className="component__sidebar__changePassword__wrap">
            <div className="route__domainsList__addDomain__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Change password</h3>
            <CustomInput autocomplete="on" ref={oldPasswordRef} accent="#fff" theme="dark" type="password" placeholder="Old password" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="on" ref={newPasswordRef} accent="#fff" theme="dark" type="password" placeholder="New password" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="on" ref={confirmPasswordRef} accent="#fff" theme="dark" type="password" placeholder="Confirm new password" style={{ width: "100%" }} />

            <div className="component__sidebar__changePassword__wrap__btns">
                <p onClick={changePassword}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span >Change</span>
                </p>
                <p onClick={onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__domainsList__addDomain__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default Sidebar;