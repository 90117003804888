import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import { updateTimestamp } from "../../actions/timestampActions";
import rpcClient from "../../modules/rpcClientModule";
import useDefer from "../../modules/hooks/useDefer.js";
import useOnScreen from "../../modules/hooks/useOnScreen.js";
import { animateBox } from "../../modules/componentAnimation";

import CustomInput from "../../components/customComponents/CustomInput";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import FilterPanel from "../../components/customComponents/FilterPanel";
import Dropdown from "../../components/customComponents/Dropdown";
import Spinner from "../../components/customComponents/Spinner";

import { WebsiteItem } from "../Websites.js";
import moment from "moment";

let curTimeout = null;

const CampaignList = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [dateFilters, setDateFilters]=React.useState("")

    const timestampRef = React.useRef();
    const timestampSelector = useSelector(state => state?.timestamp ?? "-");
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();

    const searchRef = React.useRef();

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{
                or: [
                    { name: "Name", op: "like", value: searchVal }
                ]
            }]);
        }, 500);
    };


    const getData = ts => {
        setCanPaginate(false);

        rpcClient({
            action: "call",
            method: "campaigns.getAll",
            params: {
                filters: [...filters, ...parseDateFilter(dateFilters)],
                fetchTrackingStats: true
            },
            callback: d => {
                if (timestampRef.current !== ts) return;
                if (data?.status === "ok") {
                    if (data.data.length >= 20) {
                        setCanPaginate(true);
                    };
                };
                setData(d);
            }
        });
    };

    const continueData = ts => {
        if (!data) return;
        if (data?.status !== "ok") return;
        if (!canPaginate) return;

        rpcClient({
            action: "call",
            method: "campaigns.getAll",
            params: {
                filters: [...filters, ...parseDateFilter(dateFilters)],
                fetchTrackingStats: true
            },
            callback: d => {
                if (timestampRef.current !== ts) return;
                if (d.status === "ok") {
                    if (d.data.length >= 20) setCanPaginate(true);
                    setData(c => {
                        return {
                            ...c,
                            data: [
                                ...c.data,
                                ...d.data
                            ]
                        };
                    });
                };
            }
        });
    };

    const openSitePreview = ID => {
        window.open(`${rpcClient.baseURL}/websites/preview/${ID}`, "_blank");
    };

    const parseDateFilter = (cDate) => {
        switch (cDate) {
            case "all":
                return [];
            case "today":
                return [{ name: "trackAt", op: "deq", value: moment().endOf("day") }];
            case "yesterday":
                return [{ name: "trackAt", op: "deq", value: moment().add(-1, "days").endOf("day") }];
            case "7days":
                return [{ name: "trackAt", op: "dgeq", value: moment().add(-7, "days").endOf("day") }];
            case "30days":
                return [{ name: "trackAt", op: "dgeq", value: moment().add(-30, "days").endOf("day") }];
            case "60days":
                return [{ name: "trackAt", op: "dgeq", value: moment().add(-60, "days").endOf("day") }];
            default: return [];
        };
    };

    const getTotalWebsiteStats = websites => {
        let out = {Visits: 0, Conversions: 0};

        for (let w of websites) {
            out.Visits += (w?._Tracking?.Visits ?? 0);
            out.Conversions += (w?._Tracking?.Conversions ?? 0);
        };

        return out;
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch { };

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => continueData(ts));
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;

        curDefer(() => getData(ts), 500);
    }, [timestampSelector, filters, dateFilters]);

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
        if (dateFilters !== "all") setDateFilters("all");

    }, [siteSettingsSelector?.advancedSearch]);

    return <div className="route__campaignList">
        {siteSettingsSelector?.advancedSearch ?
            <FilterPanel accent="rgb(63, 124, 234)" theme="dark" filters={[
                { name: "Name", friendlyName: "Name", type: "string" },
                { name: "URLPrefix", friendlyName: "URL prefix", type: "string" },
                
            ]} filterCB={f => setFilters(f)} /> :
            <>
                <CustomInput style={{
                    width:"100%",
                    marginBottom: "20px"
                }} autocomplete="off" ref={searchRef} onInput={searchHandler} theme="dark" accent="#3F7CEA" placeholder="Search..." />
                <div className="route__campaignList__dateFilter">
                    <span className="route__campaignList__dateFilter__head">Date: </span>
                    <div onClick={() => setDateFilters("all")} className={dateFilters === "all" ? "route__campaignList__dateFilter__item--active" : ""}><span>All</span></div>
                    <div onClick={() => setDateFilters("today")} className={dateFilters === "today" ? "route__campaignList__dateFilter__item--active" : ""}><span>Today</span></div>
                    <div onClick={() => setDateFilters("yesterday")} className={dateFilters === "yesterday" ? "route__campaignList__dateFilter__item--active" : ""}><span>Yesterday</span></div>
                    <div onClick={() => setDateFilters("7days")} className={dateFilters === "7days" ? "route__campaignList__dateFilter__item--active" : ""}><span>Last 7 days</span></div>
                    <div onClick={() => setDateFilters("30days")} className={dateFilters === "30days" ? "route__campaignList__dateFilter__item--active" : ""}><span>Last 30 days</span></div>
                    <div onClick={() => setDateFilters("60days")} className={dateFilters === "60days" ? "route__campaignList__dateFilter__item--active" : ""}><span>Last 60 days</span></div>
                </div>
            </>
        }
        {data ? <>
            {data?.status === "ok" ? <>

                {data.data.length === 0 && <p>Nothing to show...</p>}
                {data.data.length > 0 && <div className="route__campaignList__row" style={{ padding: "20px" }}>
                    <p>Campaign name</p>
                    <p>Visits</p>
                    <p>Conversions</p>
                    <p style={{ textAlign: "end" }}>Actions</p>
                </div>}

                {data.data.map(item => {
                    return <div className="route__campaignList__item">
                        <div className="route__campaignList__item__row">
                            <p>{item.SiteGuard ? <span style={{color: "rgb(234, 145, 63)"}}>[Site Guard]&nbsp;</span> : ""}{item.Name}</p>
                            <p>{item._Tracking?.Visits ?? 0}</p>
                            <p>{item._Tracking?.Conversions ?? 0}</p>
                            <p className="route__campaignList__item__row__actions">
                                <p onClick={() => animateBox(<GetCampaignURL data={item} />)}>
                                    <img src="/images/wwwIcon.png" />
                                </p>
                                <p onClick={() => animateBox(<AddCampaign edit={true} data={item} onChange={() => {
                                    let ts = Date.now();
                                    timestampRef.current = ts;
                                    getData(ts);
                                }} />)}>
                                    <img src="/images/editCampaign.png" />
                                </p>
                                <p onClick={() => animateBox(<RemoveCampaign item={item} onChange={() => {
                                    let ts = Date.now();
                                    timestampRef.current = ts;
                                    getData(ts);
                                }} />)}>
                                    <img src="/images/removeCampaign.png" />
                                </p>
                            </p>
                        </div>

                        {item.BotPages?.length > 0 && <>
                            <div className="route__campaignList__item__row route__campaignList__item__row--inset">
                                <p>Site Guard lages</p>
                                <p>{getTotalWebsiteStats(item._BotPages).Visits}</p>
                                <p>-</p>
                                <p className="route__campaignList__item__row__actions"></p>
                            </div>
                            {item._BotPages.map(w => {
                                return <div className="route__campaignList__item__row route__campaignList__item__row--inset route__campaignList__item__row--inset2">
                                    <p>{w.Name} (ID: {w.ID})</p>
                                    <p>{w?._Tracking?.Visits ?? 0}</p>
                                    <p>-</p>
                                    <p className="route__campaignList__item__row__actions">
                                        <p onClick={() => openSitePreview(w.ID)}>
                                            <img src="/images/view.png" />
                                        </p>
                                    </p>
                                </div>
                            })}
                        </>}

                        {item._PrelandingPages?.length > 0 && <>
                            <div className="route__campaignList__item__row route__campaignList__item__row--inset">
                                <p>Pre-landing lages</p>
                                <p>{getTotalWebsiteStats(item._PrelandingPages).Visits}</p>
                                <p>-</p>
                                <p className="route__campaignList__item__row__actions"></p>
                            </div>
                            {item._PrelandingPages.map(w => {
                                return <div className="route__campaignList__item__row route__campaignList__item__row--inset route__campaignList__item__row--inset2">
                                    <p>{w.Name} (ID: {w.ID})</p>
                                    <p>{w?._Tracking?.Visits ?? 0}</p>
                                    <p>-</p>
                                    <p className="route__campaignList__item__row__actions">
                                        <p onClick={() => openSitePreview(w.ID)}>
                                            <img src="/images/view.png" />
                                        </p>
                                    </p>
                                </div>
                            })}
                        </>}

                        {item._LandingPages?.length > 0 && <>
                            <div className="route__campaignList__item__row route__campaignList__item__row--inset">
                                <p>Landing lages</p>
                                <p>{getTotalWebsiteStats(item._LandingPages).Visits}</p>
                                <p>{getTotalWebsiteStats(item._LandingPages).Conversions}</p>
                                <p className="route__campaignList__item__row__actions"></p>
                            </div>
                            {item._LandingPages.map(w => {
                                return <div className="route__campaignList__item__row route__campaignList__item__row--inset route__campaignList__item__row--inset2">
                                    <p>{w.Name} (ID: {w.ID})</p>
                                    <p>{w?._Tracking?.Visits ?? 0}</p>
                                    <p>{w?._Tracking?.Conversions ?? 0}</p>
                                    <p className="route__campaignList__item__row__actions">
                                        <p onClick={() => openSitePreview(w.ID)}>
                                            <img src="/images/view.png" />
                                        </p>
                                    </p>
                                </div>
                            })}
                        </>}

                        {item._ThankyouPages?.length > 0 && <>
                            <div className="route__campaignList__item__row route__campaignList__item__row--inset">
                                <p>Thank you lages</p>
                                <p>{getTotalWebsiteStats(item._ThankyouPages).Visits}</p>
                                <p>-</p>
                                <p className="route__campaignList__item__row__actions"></p>
                            </div>
                            {item._ThankyouPages.map(w => {
                                return <div className="route__campaignList__item__row route__campaignList__item__row--inset route__campaignList__item__row--inset2">
                                    <p>{w.Name} (ID: {w.ID})</p>
                                    <p>{w?._Tracking?.Visits ?? 0}</p>
                                    <p>-</p>
                                    <p className="route__campaignList__item__row__actions">
                                        <p onClick={() => openSitePreview(w.ID)}>
                                            <img src="/images/view.png" />
                                        </p>
                                    </p>
                                </div>
                            })}
                        </>}
                    </div>
                })}

                {canPaginate && <div style={{ width: "100%" }} ref={curOnScreen.measureRef}></div>}

            </> : <p>There was an error while fetching campaigns</p>}
        </> : <Spinner color="white" />}
    </div>
};

const AddCampaign = (props) => {
    const [firstEntry, setFirstEntry] = React.useState(true);
    const [offers, setOffers] = React.useState();
    const [websites, setWebsites] = React.useState();
    const [domains, setDomains] = React.useState();
    const [infoP, setInfoP] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);

    const typesSelector = useSelector(state => state?.types ?? {});
    const websiteTrafficSourcesSelector = useSelector(state => state?.types?.websiteTrafficSources ?? []);
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const [selectedOffer, setSelectedOffer] = React.useState(props?.data?.OfferID ?? null);
    const [selectedTrafficSource, setSelectedTrafficSource] = React.useState();
    const [selectedDomain, setSelectedDomain] = React.useState(props?.data?.DomainID ?? null);
    const [selectedFlowType, setSelectedFlowType] = React.useState(props?.data?.FlowType ?? null);
    const [selectedSiteGuard, setSelectedSiteGuard] = React.useState(() => {
        if (userInfoSelector?.Flags?.isAdmin) return props?.data?.SiteGuard ?? true;
        return true;
    });

    const [selectedBotWebsites, setSelectedBotWebsites] = React.useState(props?.data?.BotPages ?? []);
    const [selectedPrelandingWebsites, setSelectedPrelandingWebsites] = React.useState(props?.data?.PrelandingPages ?? []);
    const [selectedLandingWebsites, setSelectedLandingWebsites] = React.useState(props?.data?.LandingPages ?? []);
    const [selectedThankyouWebsites, setSelectedThankyouWebsites] = React.useState(props?.data?.ThankyouPages ?? []);

    const nameRef = React.useRef();
    const urlPrefixRef = React.useRef();

    const curDispatch = useDispatch();

    const addCampaign = () => {
        setInfoP("");

        let data = {
            OfferID: selectedOffer,
            DomainID: selectedDomain,
            Name: nameRef.current.value,
            URLPrefix: urlPrefixRef.current.value,
            FlowType: selectedFlowType,
            SiteGuard: !!selectedSiteGuard,
            TrafficSource: selectedTrafficSource,

            BotPages: selectedBotWebsites,
            PrelandingPages: selectedPrelandingWebsites,
            LandingPages: selectedLandingWebsites,
            ThankyouPages: selectedThankyouWebsites
        };
        if (props.edit) data.ID = props.data.ID;

        if (!data.OfferID) return setInfoP("Offer can't be empty");
        if (!data.TrafficSource) return setInfoP("Traffic source can't be empty");
        if (!data.DomainID) return setInfoP("Domain can't be empty");
        if (!data.Name) return setInfoP("Name can't be empty");
        if (!data.FlowType) return setInfoP("Flow type can't be empty");

        if (selectedSiteGuard && data.BotPages.length === 0) return setInfoP("Site Guard pages can't be empty if the 'Site Guard' is enabled!");
        if (data.LandingPages.length === 0) return setInfoP("Select at least 1 landing page");
        if (data.ThankyouPages.length === 0) return setInfoP("Select at least 1 thank you page");

        setSpinner(true);
        rpcClient({
            action: "call",
            method: props.edit ? "campaigns.edit" : "campaigns.add",
            args: data,
            callback: c => {
                setSpinner(false);
                if (c.status === "ok") {
                    if (props.onChange) props.onChange();
                    curDispatch(updateTimestamp());
                    props.onClose();
                } else {
                    setInfoP("There was an error while creating the campaign.");
                };
            }
        });
    };

    React.useEffect(() => {
        if (props.edit && firstEntry) {
            setFirstEntry(false);
            return;
        }
        setSelectedPrelandingWebsites([]);
        setSelectedLandingWebsites([]);
        setSelectedThankyouWebsites([]);
    }, [selectedOffer]);

    React.useEffect(() => {
        setSelectedBotWebsites([]);
    }, [selectedSiteGuard]);

    React.useEffect(() => {
        rpcClient([
            {
                action: "call",
                method: "websites.getAll",
                params: { limit: null },
                callback: setWebsites
            },
            {
                action: "call",
                method: "offers.getAll",
                params: { limit: null },
                callback: setOffers
            },
            {
                action: "call",
                method: "domains.getAllDomains",
                params: { limit: null },
                callback: setDomains
            }
        ]);
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__spinner" style={{ opacity: spinner ? 1 : 0, pointerEvents: spinner ? "all" : "none" }} onClick={e => {
                if (!spinner) return;
                e.stopPropagation();
                e.preventDefault();
            }}>
                <Spinner color="white" style={{pointerEvents: "none"}} />
            </div>

            {(offers && websites) ? <>
                {(offers?.status === "ok" && websites?.status === "ok") ? <>
                    <h3 style={{ marginBottom: "20px" }}>{props.id ? "Edit" : "Add"} campaign</h3>

                    <CustomInput ref={nameRef} defaultValue={props?.data?.Name} autocomplete="off" accent="#fff" theme="dark" placeholder="Campaign name" style={{ width: "100%", marginBottom: "20px" }} />
                    <CustomInput ref={urlPrefixRef} defaultValue={props?.data?.URLPrefix} autocomplete="off" accent="#fff" theme="dark" placeholder="Unique URL prefix (optional)" style={{ width: "100%", marginBottom: "20px" }} />

                    <Dropdown
                        style={{ marginTop: "10px", marginBottom: "0px" }}
                        theme="dark" accent="rgb(150, 150, 152)"
                        data={offers.data.map(o => {
                            return {
                                name: <><span style={{ color: "#ea913f" }}>Offer: </span>{`${o.OfferName} (${o.OfferType ?? "-"}, ${o.Country ?? "-"})`}</>,
                                value: o.ID,
                                search: `${o.OfferName} (${o.OfferType ?? "-"}, ${o.Country ?? "-"})`
                            };
                        })}
                        onChange={e => {
                            if (e?.value) setSelectedOffer(e?.value);
                        }}
                        selected={(() => {
                            if (!selectedOffer) return null;
                            if (offers?.status !== "ok") return null;

                            return offers.data.indexOf(offers.data.find(o => o.ID === selectedOffer));
                        })()}
                        inlinePlaceholder="Offer"
                    />

                    <Dropdown
                        style={{ marginTop: "10px", marginBottom: "0px" }}
                        theme="dark" accent="rgb(150, 150, 152)"
                        data={websiteTrafficSourcesSelector.map(ts => {
                            return {
                                name: <><span style={{ color: "#ea913f" }}>Traffic source: </span>{ts}</>,
                                value: ts,
                                search: ts
                            };
                        })}
                        onChange={e => {
                            if (e?.value) setSelectedTrafficSource(e?.value);
                        }}
                        selected={(() => {
                            if (!selectedTrafficSource) return null;
                            return websiteTrafficSourcesSelector.indexOf(selectedTrafficSource);
                        })()}
                        inlinePlaceholder="Traffic source"
                    />

                    <Dropdown
                        style={{ marginTop: "10px", marginBottom: "0px" }}
                        theme="dark" accent="rgb(150, 150, 152)"
                        data={domains.data.map(d => {
                            return {
                                name: <><span style={{ color: "#ea913f" }}>Domain: </span>{d.Name}</>,
                                value: d.ID,
                                search: d.Name
                            };
                        })}
                        onChange={e => {
                            if (e?.value) setSelectedDomain(e?.value);
                        }}
                        selected={(() => {
                            if (!selectedDomain) return null;
                            if (domains?.status !== "ok") return null;
                            return domains.data.indexOf(domains.data.find(d => d.ID === Number(selectedDomain)));
                        })()}
                        inlinePlaceholder="Domain"
                    />

                    <Dropdown
                        style={{ marginTop: "10px", marginBottom: "0px" }}
                        theme="dark" accent="rgb(150, 150, 152)"
                        data={Object.keys(typesSelector?.campaignFlowTypes).map(f => {
                            return {
                                name: <><span style={{ color: "#ea913f" }}>Flow type: </span>{typesSelector.campaignFlowTypes[f]}</>,
                                value: f,
                                search: typesSelector.campaignFlowTypes[f]
                            };
                        })}
                        onChange={e => {
                            if (e?.value) setSelectedFlowType(Number(e?.value));
                        }}
                        selected={(() => {
                            if (!selectedFlowType) return null;

                            return Object.keys(typesSelector?.campaignFlowTypes).indexOf(String(selectedFlowType));
                        })()}
                        inlinePlaceholder="Flow type"
                    />

                    {userInfoSelector?.Flags?.isAdmin && <CustomCheckbox
                        placeholder="Site Guard"
                        theme="dark"
                        style={{ marginTop: "20px" }}
                        defaultValue={!!selectedSiteGuard}
                        onChange={e => setSelectedSiteGuard(!!e)}
                    />}

                    {(selectedOffer && selectedTrafficSource) ? <>
                        {selectedSiteGuard && <div className="route__campaignList__websites">
                            <div className="route__campaignList__websites__top">
                                <p className="route__campaignList__websites__top__left"><span style={{ color: "rgb(234, 145, 63)" }}>Site Guard</span> pages</p>
                                <p className="route__campaignList__websites__top__right" onClick={() => animateBox(<SelectWebsiteModal
                                    websiteType={4}
                                    offer={selectedOffer}
                                    offers={offers.data}
                                    trafficSource={selectedTrafficSource}
                                    onChange={w => setSelectedBotWebsites(lw => [...new Set([...lw, w])])}
                                />)}>
                                    <img src="/images/addBtn.png" />
                                    <span>Add</span>
                                </p>
                            </div>


                            <div className="route__campaignList__websites__list">
                                {selectedBotWebsites.map(w => {
                                    let curWebsite = websites.data.find(cw => cw.ID === w);
                                    return <p>
                                        <img src="/images/trashCan.png" onClick={() => setSelectedBotWebsites(cw => cw.filter(cwf => cwf !== w))} />
                                        <span>[ {w} ] {curWebsite?.Name ?? "?"}</span>
                                    </p>
                                })}
                            </div>
                        </div>}

                        <div className="route__campaignList__websites">
                            <div className="route__campaignList__websites__top">
                                <p className="route__campaignList__websites__top__left">Pre-landing pages</p>
                                <p className="route__campaignList__websites__top__right" onClick={() => animateBox(<SelectWebsiteModal
                                    websiteType={1}
                                    offer={selectedOffer}
                                    offers={offers.data}
                                    trafficSource={selectedTrafficSource}
                                    onChange={w => setSelectedPrelandingWebsites(lw => [...new Set([...lw, w])])}
                                />)}>
                                    <img src="/images/addBtn.png" />
                                    <span>Add</span>
                                </p>
                            </div>


                            <div className="route__campaignList__websites__list">
                                {selectedPrelandingWebsites.map(w => {
                                    let curWebsite = websites.data.find(cw => cw.ID === w);
                                    return <p>
                                        <img src="/images/trashCan.png" onClick={() => setSelectedPrelandingWebsites(cw => cw.filter(cwf => cwf !== w))} />
                                        <span>[ {w} ] {curWebsite?.Name ?? "?"}</span>
                                    </p>
                                })}
                            </div>
                        </div>

                        <div className="route__campaignList__websites">
                            <div className="route__campaignList__websites__top">
                                <p className="route__campaignList__websites__top__left">Landing pages</p>
                                <p className="route__campaignList__websites__top__right" onClick={() => animateBox(<SelectWebsiteModal
                                    websiteType={2}
                                    offer={selectedOffer}
                                    offers={offers.data}
                                    trafficSource={selectedTrafficSource}
                                    onChange={w => setSelectedLandingWebsites(lw => [...new Set([...lw, w])])}
                                />)}>
                                    <img src="/images/addBtn.png" />
                                    <span>Add</span>
                                </p>
                            </div>


                            <div className="route__campaignList__websites__list">
                                {selectedLandingWebsites.map(w => {
                                    let curWebsite = websites.data.find(cw => cw.ID === w);
                                    return <p>
                                        <img src="/images/trashCan.png" onClick={() => setSelectedLandingWebsites(cw => cw.filter(cwf => cwf !== w))} />
                                        <span>[ {w} ] {curWebsite?.Name ?? "?"}</span>
                                    </p>
                                })}
                            </div>
                        </div>

                        <div className="route__campaignList__websites">
                            <div className="route__campaignList__websites__top">
                                <p className="route__campaignList__websites__top__left">Thank you pages</p>
                                <p className="route__campaignList__websites__top__right" onClick={() => animateBox(<SelectWebsiteModal
                                    websiteType={3}
                                    offer={selectedOffer}
                                    offers={offers.data}
                                    trafficSource={selectedTrafficSource}
                                    onChange={w => setSelectedThankyouWebsites(lw => [...new Set([...lw, w])])}
                                />)}>
                                    <img src="/images/addBtn.png" />
                                    <span>Add</span>
                                </p>
                            </div>


                            <div className="route__campaignList__websites__list">
                                {selectedThankyouWebsites.map(w => {
                                    let curWebsite = websites.data.find(cw => cw.ID === w);
                                    return <p>
                                        <img src="/images/trashCan.png" onClick={() => setSelectedThankyouWebsites(cw => cw.filter(cwf => cwf !== w))} />
                                        <span>[ {w} ] {curWebsite?.Name ?? "?"}</span>
                                    </p>
                                })}
                            </div>
                        </div>
                    </> : null}

                    <div className="genericModal__wrap__btns">
                        {(selectedOffer && selectedTrafficSource) && <p onClick={addCampaign}>
                            <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                            <span>Save</span>
                        </p>}
                        <p onClick={props.onClose} style={{ marginLeft: "auto" }}>
                            <span>Cancel</span>
                            <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                        </p>
                    </div>

                    {infoP && <p className="route__domainsList__addDomain__wrap__infoP">{infoP}</p>}
                </> : <p className="genericModal__wrap__infoP">There was an error while fetching data</p>}
            </> : <Spinner color="white" />}

        </div>
    </div>
};

const RemoveCampaign = props => {
    const [spinner, setSpinner] = React.useState(false);

    const removeCampaign = () => {
        setSpinner(true);

        rpcClient({
            action: "call",
            method: "campaigns.remove",
            params: { ID: props.item.ID }
        }).finally(() => {
            setSpinner(false);
            props.onChange();
            props.onClose();
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__spinner" style={{ opacity: spinner ? 1 : 0, pointerEvents: spinner ? "all" : "none" }} onClick={e => spinner && e?.stopPropagation()}>
                <Spinner color="white" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Remove campaign</h3>

            <p>Are you sure? You are about to remove <span style={{ color: "rgb(63, 124, 234)" }}>{props.item.Name}</span></p>
            <p>This action cannot be undone!</p>

            <div className="genericModal__wrap__btns">
                <p onClick={removeCampaign}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Remove</span>
                </p>
                <p onClick={props.onClose} style={{ marginLeft: "auto" }}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>
        </div>
    </div>
};

const SelectWebsiteModal = props => {
    const [websites, setWebsites] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState();

    const timestampRef = React.useRef();
    const firstTimeRef = React.useRef(true);

    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const getData = ts => {
        if (timestampRef.current !== ts);
        setCanPaginate(false);

        rpcClient({
            action: "call",
            method: "websites.getAll",
            args: {
                limit: 20,
                offset: 0,
                filters: [
                    {name: "Status", op: "geq", value: 100},
                    ...(props.offer ? [{and: [{ or: [
                        {name: "OfferID", op: "eq", value: props.offer},
                        {name: "OfferID", op: "eq", value: null}
                    ] }]}] : []),
                    ...(props.trafficSource ? [{and: [{or: [
                        {name: "TrafficSource", op: "eq", value: props.trafficSource},
                        {name: "TrafficSource", op: "eq", value: null}
                    ]}]}] : []),
                    ...(props.websiteType ? [{ name: "Type", op: "eq", value: props.websiteType }] : []),
                    ...(search ? [{
                        and: [{
                            or: [
                                { name: "ID", op: "like", value: search },
                                { name: "Name", op: "like", value: search }
                            ]
                        }]
                    }] : [])
                ],
                orders: [{ name: "createdAt", order: "desc" }]
            },
            callback: d => {
                if (timestampRef.current !== ts) return;
                if (d.status === "ok") {
                    if (d.data.length >= 20) setCanPaginate(true);
                };
                setWebsites(d);
            }
        });
    };

    const continueData = ts => {
        if (!websites) return;
        if (websites?.status !== "ok") return;
        if (timestampRef.current !== ts);
        if (!canPaginate) return;
        setCanPaginate(false);

        rpcClient({
            action: "call",
            method: "websites.getAll",
            args: {
                limit: 20,
                offset: 0,
                filters: [
                    {name: "Status", op: "geq", value: 100},
                    ...(props.offer ? [{and: [{ or: [
                        {name: "OfferID", op: "eq", value: props.offer},
                        {name: "OfferID", op: "eq", value: null}
                    ] }]}] : []),
                    ...(props.trafficSource ? [{and: [{or: [
                        {name: "TrafficSource", op: "eq", value: props.trafficSource},
                        {name: "TrafficSource", op: "eq", value: null}
                    ]}]}] : []),
                    ...(props.websiteType ? [{ name: "Type", op: "eq", value: props.websiteType }] : []),
                    ...(search ? [{
                        and: [{
                            or: [
                                { name: "ID", op: "like", value: search },
                                { name: "Name", op: "like", value: search },
                                { name: "TrafficSource", op: "like", value: search }
                            ]
                        }]
                    }] : []),

                    { name: "ID", op: "notIn", value: websites.data.map(d => d.ID) }
                ],
                orders: [{ name: "createdAt", order: "desc" }]
            },
            callback: d => {
                if (timestampRef.current !== ts) return;
                if (d.status === "ok") {
                    if (d.data.length >= 20) setCanPaginate(true);
                    setWebsites(w => {
                        return {
                            ...w,
                            data: [
                                ...w.data,
                                ...d.data
                            ]
                        };
                    });
                };
            }
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        setCanPaginate(false);

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch { };

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        };

        if (websites) setWebsites();
        let interval = firstTimeRef.current ? 0 : 1000;
        if (firstTimeRef.current) firstTimeRef.current = false;
        curDefer(handler, interval);
    }, [search]);

    return <div className="route__campaignList__selectWebsite">
        <div className="route__campaignList__selectWebsite__wrap">
            <div className="route__campaignList__selectWebsite__wrap__top">
                <CustomInput placeholder="Search..." theme="dark" onChange={e => setSearch(e?.target?.value)} />

                <div className="route__campaignList__selectWebsite__wrap__top__btns">
                    <p onClick={props.onClose}>
                        <span>Close</span>
                        <img src="/images/closeBtn.png" />
                    </p>
                </div>
            </div>
            <div className="route__campaignList__selectWebsite__wrap__bottom">
                {websites ? (websites?.status === "ok" ? <>
                    {websites.data.length > 0 ? <>
                        {websites.data.map(w => {
                            return <WebsiteItem
                                key={`${w.ID}-${w.updatedAt}`}
                                data={w}
                                offers={props.offers}
                                onSelect={() => {
                                    props.onChange(w.ID);
                                    props.onClose();
                                }}
                            />
                        })}

                        {canPaginate && <div ref={curOnScreen.measureRef} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Spinner color="white" />
                        </div>}
                    </> : <p>Nothing to show...</p>}
                </> : <p className="route__domainsList__addDomain__wrap__infoP">There was an error while fetching websites!</p>) : <Spinner color="white" />}
            </div>
        </div>
    </div>
};

const GetCampaignURL = props => {
    const [data, setData] = React.useState();

    const copyToClipboard = () => {
        try { navigator.clipboard.writeText(data.data); } catch {};
        props.onClose();
    };

    const viewCampaign = () => {
        let tmp = new URL(data.data);
        tmp.searchParams.set("sttest", 1);
        window.open(tmp.toString(), "_blank");
    };

    React.useEffect(() => {
        rpcClient({
            action: "call",
            method: "campaigns.getURL",
            args: {ID: props.data.ID},
            callback: setData
        })
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap" style={{width: "500px"}}>
            <h3 style={{ marginBottom: "20px" }}>Campaign URL</h3>

            {data ? <>
                {data.status === "ok" ? <>
                    <p style={{marginBottom: "20px"}}>URL for <span style={{color: "rgb(63, 124, 234)"}}>{props.data.Name}</span></p>
                    <p>{data.data}</p>
                </> : <p className="genericModal__wrap__infoP">There was an error while fetching the campaign URL</p>}
            </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}

            <div className="genericModal__wrap__btns" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <p onClick={copyToClipboard}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Copy to clipboard</span>
                </p>

                <p onClick={viewCampaign}>
                    <img
                        src="/images/view.png"
                        style={{
                            marginRight: "15px",
                            borderRadius: "50%",
                            padding: "6px",
                            backgroundColor: "rgb(63, 124, 234)"
                        }}
                    />
                    <span>Preview</span>
                </p>

                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>
        </div>
    </div>
};

export default CampaignList;
export { AddCampaign };