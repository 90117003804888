import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";

import * as timestampActions from "../../actions/timestampActions";
import rpcClient from "../../modules/rpcClientModule";
import useOnScrron from "../../modules/hooks/useOnScreen";
import useDefer from "../../modules/hooks/useDefer";
import { animateBox } from "../../modules/componentAnimation";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Dropdown from "../../components/customComponents/Dropdown";
import Spinner from "../../components/customComponents/Spinner";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";

const AllowedSites = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const timestampRef = React.useRef();
    const curOnScreen = useOnScrron();
    const curDefer = useDefer();

    const timestampSelector = useSelector(state => state?.timestamp);

    const getData = ts => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        rpcClient({
            action: "call",
            method: "allowedWebsites.getAll",
            params: {
                orders: [{name: "createdAt", order: "desc"}]
            },
            callback: d => {
                if (timestampRef.current !== ts) return;
                if (d.status === "error") return setData(d);
                if (d.data.length >= 20) setCanPaginate(true);
                setData(d);
            }
        });
    };

    const continueData = ts => {
        if (timestampRef.current !== ts) return;
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;
        setCanPaginate(false);

        rpcClient({
            action: "call",
            method: "allowedWebsites.getAll",
            params: {
                orders: [{name: "createdAt", order: "desc"}],
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            callback: d => {
                if (timestampRef.current !== ts) return;
                if (d.status === "error") return setData(d);
                if (d.data.length >= 20) setCanPaginate(true);
                setData(dd => {
                    return {
                        ...d,
                        data: [
                            ...dd.data,
                            ...d.data
                        ]
                    };
                });
            }
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => continueData(ts), 500);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => getData(ts), 500);
    }, [timestampSelector]);

    return <div className="route__allowedSites">
        <FilteredCustomTable
            theme="dark"
            accent="#48515C"
            headers={["User", "Offer", "Website", "is safe", "Allowed by"]}
            customColumns={(new Array(5)).fill("max-content")}
            style={{columnGap: "40px"}}
            data={(() => {
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data"}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item?._User?.Username},
                        {keyID: item.ID, type: "text", text: `${item?._Offer?.OfferName} (${item?._Offer?.Country ?? "?"}, ${item?._Offer?.OfferType ?? "?"})`},
                        {keyID: item.ID, type: "text", text: item?._Website?.Name},
                        {keyID: item.ID, type: "text", text: item.isSafe ? "Yes" : "No", style: {color: item.isSafe ? "#6ef26e" : "#f26e6e"}},
                        {keyID: item.ID, type: "text", text: item?._CreatedBy?.Username},

                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "button", text: "Remove", onClick: () => animateBox(<RemoveAllowedSite ID={item.ID} />)}
                        ]}
                    ]);

                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "100%"}}></div>}
    </div>
};

const AddAllowedSite = (props) => {
    const [infoP, setInfoP] = React.useState("");
    const [spinner, setSpinner] = React.useState();
    const [offers, setOffers] = React.useState();
    const [websites, setWebsites] = React.useState();
    const [users, setUsers] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState();
    const [selectedWebsite, setSelectedWebsite] = React.useState();
    const [selectedSafe, setSelectedSafe] = React.useState(false);

    const websiteTypesSelector = useSelector(state => state?.types?.websiteTypes ?? {});
    const curDispatch = useDispatch();

    const addAllowedSite = () => {
        setInfoP("");

        let data = {
            UserID: selectedUser,
            OfferID: selectedOffer,
            WebsiteID: selectedWebsite,
            isSafe: selectedSafe
        };

        if (!data.UserID) return setInfoP("User can't be empty");
        if (data.OfferID === undefined) return setInfoP("Offer can't be empty");
        if (!data.WebsiteID) return setInfoP("Website can't be empty");

        setSpinner(true);
        rpcClient({
            action: "call",
            method: "allowedWebsites.add",
            params: data,
            callback: d => {
                if (d.status === "ok") {
                    curDispatch(timestampActions.updateTimestamp());
                    props.onClose();
                } else {
                    setInfoP("There was an error while adding the allowance.")
                };
            }
        }).finally(() => setSpinner(false))
    };

    const getUserSTID = () => {
        if (!selectedUser) return "";
        if (users?.status !== "ok") return "";
        let foundUser = users.data.find(u => u.ID === selectedUser);
        if (!foundUser) return "";
        return foundUser.TrackID;
    };

    React.useEffect(() => {
        setSelectedWebsite();
    }, [selectedOffer]);
    React.useEffect(() => {
        setSelectedOffer();
    }, [selectedUser]);

    React.useEffect(() => {
        setSpinner(true);
        rpcClient([
            {
                action: "call",
                method: "offers.getAll",
                params: {limit: null},
                callback: setOffers
            },
            {
                action: "call",
                method: "websites.getAll",
                params: {
                    limit: null,
                    filters: [{name: "Status", op: "geq", value: 100}]
                },
                callback: setWebsites
            },
            {
                action: "call",
                method: "users.getAll",
                params: {limit: null},
                callback: setUsers
            }
        ]).finally(() => {
            setSpinner(false);
        });
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">

        <div className="genericModal__wrap__spinner" style={{pointerEvents: spinner ? "all" : "none", opacity: spinner ? 1 : 0}} onClick={e => spinner && e?.stopPropagation()}>
            <Spinner color="white" style={{pointerEvents: "none"}} />
        </div>

        <h3 style={{marginBottom: "20px"}}>{props.edit ? "Edit" : "Add"} allowed site</h3>

        {(users && offers && websites) ? <>
            <Dropdown
                theme="dark"
                accent="#fff"
                inlinePlaceholder="Select user"
                style={{marginBottom: "20px"}}
                data={users.data.map(usr => {
                    return {value: usr.ID, name: <><span style={{color: "rgb(234, 145, 63)"}}>User: </span> {usr.Username}</>, search: usr.Username}
                })}
                onChange={e => e?.value && setSelectedUser(e?.value)}
                selected={(()=>{
                    if (!selectedUser) return null;
                    if (users?.status !== "ok") return null;
                    return users.data.indexOf(users.data.map(u => u.ID === selectedUser));
                })()}
            />

            {selectedUser && <Dropdown
                theme="dark"
                accent="#fff"
                inlinePlaceholder="Select offer"
                style={{marginBottom: "20px"}}
                data={[{name: "Any offer", value: null}, ...offers.data.filter(o => o.ResponsiblePerson.find(or => or.ID === getUserSTID())).map(offer => {
                    return {value: offer.ID, name: <><span style={{color: "rgb(234, 145, 63)"}}>Offer: </span>{offer.OfferName} ({offer.Country ?? "?"}, {offer.OfferType ?? "?"})</>, search: `${offer.OfferName} (${offer.Country ?? "?"}, ${offer.OfferType ?? "?"})`}
                })]}
                onChange={e => e?.value !== undefined && setSelectedOffer(e?.value)}
                selected={(() => {
                    if (selectedOffer !== undefined) return null;
                    if (selectedOffer !== null) return 0;
                    if (offers?.status !== "ok") return null;
                    let filteredOffers = offers.data.filter(o => o.ResponsiblePerson.find(or => or.ID === getUserSTID()));
                    return filteredOffers.indexOf(filteredOffers.find(w => w.ID === selectedOffer)) + 1;
                })()}
            />}

            {(selectedUser && selectedOffer !== undefined) && <Dropdown
                theme="dark"
                accent="#fff"
                inlinePlaceholder="Select website"
                style={{marginBottom: "20px"}}
                data={websites.data.filter(w => w.OfferID === selectedOffer).map(website => {
                    return {value: website.ID, name: <><span style={{color: "rgb(234, 145, 63)"}}>Website: </span>(ID: {website.ID}, {websiteTypesSelector[website.Type]?.replace?.(" site", "")}) {website.Name}</>, search: `(${website.ID}, ${websiteTypesSelector[website.Type]?.replace?.(" site", "")}) ${website.Name}`}
                })}
                onChange={e => e?.value && setSelectedWebsite(e?.value)}
                selected={(()=>{
                    if (!selectedWebsite) return null;
                    if (websites?.status !== "ok") return null;
                    let filteredSites = websites.data.filter(w => w.OfferID === selectedOffer);
                    return filteredSites.indexOf(filteredSites.find(w => w.ID === selectedWebsite));
                })()}
            />}

            <CustomCheckbox
                theme="dark"
                placeholder="Lander is safe (no need for Site Guard)"
                defaultValue={selectedSafe}
                onChange={e => selectedSafe !== e && setSelectedSafe(e)}
            />
        </> : <p className="genericModal__wrap__infoP">There was an error while fetching data!</p>}

        {infoP && <p className="genericModal__wrap__infoP">{infoP}</p>}

        <div className="genericModal__wrap__btns">
            <p onClick={addAllowedSite}>
                <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                <span>Save</span>
            </p>
            <p onClick={props.onClose} style={{marginLeft: "auto"}}>
                <span>Cancel</span>
                <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
            </p>
        </div>

        </div>
    </div>
};

const RemoveAllowedSite = props => {
    const [spinner, setSpinner] = React.useState();
    const [infoP, setInfoP] = React.useState();

    const curDispatch = useDispatch();

    const removeAllowedSite = () => {
        setInfoP("");
        setSpinner(true);
        rpcClient({
            action: "call",
            method: "allowedWebsites.remove",
            params: {ID: props.ID},
            callback: d => {
                if (d.status === "ok") {
                    curDispatch(timestampActions.updateTimestamp());
                    props.onClose();
                } else {
                    setInfoP("There was an error while removing the allowance.");
                };
            }
        }).finally(() => setSpinner(false));
    };
    
    return <div className="genericModal">
        <div className="genericModal__wrap">

            <div className="genericModal__wrap__spinner" style={{opacity: spinner ? 1 : 0, pointerEvents: spinner ? "all" : "none"}} onClick={e => spinner && e?.stopPropagation()}>
                <Spinner color="white" style={{pointerEvents: "none"}} />
            </div>

            <h3 style={{marginBottom: "20px"}}>Remove allowed website</h3>
            <p>Are you sure? This action is irreversible!</p>

            {infoP && <p className="genericModal__wrap__infoP">{infoP}</p>}

            <div className="genericModal__wrap__btns">
                <p onClick={removeAllowedSite}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose} style={{marginLeft: "auto"}}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

        </div>
    </div>
};

export default AllowedSites;
export { AddAllowedSite }; 